<template>
    <div id="app" :class="hotspotContentOverlayed">
        <div class="header-nav" v-if="isLoggedIn">
            <img :src="mainLogo" :alt="mainTitle" class="logo" @click="hideOverlayedComponents()" />
            <div class="header-nav-links">
                <ul>
                    <li v-for="module in modules" v-if="!module.isOutside" :class="getModuleLinkClassName(module)" @click="module.open()">{{$t('module.' + module.name)}}</li>                    
                </ul>
            </div>

            <div class="header-nav-controls" v-if="hasNavControls">
                <div class="one-day-counter">
                    <i class="icon-calendar-empty"></i>
                    <div class="one-day-counter__text">{{ $t('day-one-in') }}:</div>
                    <div class="one-day-counter__count-down">{{ countdown }}</div>
                </div>
                <button class="btn-transparent notifications" @click="toggleNotifications()">
                    <i class="icon-notification"></i>
                    <span class="notification-badge label-badge label-badge-sm"></span>
                </button>
                <notifications :items="notifications" @notificationMenuCollapsed="handleNotificationMenuCollapsed" ref="notificationMenu"/>
            </div>

            <div class="header-nav-profile" v-if="hasNavControls">
                <div class="profile-picture-wrap">
                    <span class="circle-image" @click="showMyProfile()"><span class="circle-image__inner"><img src="/img/profile-picture.png" alt="profile" /></span></span>
                </div>

                <div class="detailed-nav">
                    <button class="btn-transparent" @click="toggleSidebars()"><i :class="sidebarsIcon"></i></button>
                </div>
            </div>
        </div>

        <div id="layout" v-if="isLoggedIn">
            <marzipano :linkHotspots="linkHotspots" :hotspots="hotspots" :displayedScenes="displayedScenes" ref="marzipano" @sceneChange="sceneChange" @openHalfBox="openHalfBox" @moreLinkClicked="moreLinkClicked" @hotspotContentToggled="hotspotContentToggled" @VRDisplayFound="VRDisplayFound" @CBViewLeaved="CBViewLeaved" @updateProgress="updateProgress" @hideOpenedHotspot="hideOpenedHotspot" @toggleHotspotContent="toggleHotspotContent" @hotspotVoiceOver="hotspotVoiceOver" @openLinkValidate="openLinkValidate" :checklist="checklist" />
        </div>

        <button class="btn-transparent" id="CBDisplayButton" @click="toggleCBDisplay()" :title="$t('controls.toggleCBDisplay')" v-if="isLoggedIn"><i class="icon-vr-view"></i></button>

        <button class="btn-transparent" id="LogoutButton" @click="logout()" :title="$t('logout')" v-if="isLoggedIn">{{ $t('logout') }} <img :src="require('@/assets/icon-logout.svg')" alt="Logout" /></button>

        <div class="sidebars" :class="sidebarsClassName" v-if="isLoggedIn">
            <box-menu :items="menuItems" @menuItemOpened="menuItemOpened" @beforeMenuItemOpen="beforeMenuItemOpen" @openOtherTarget="openHalfBox()" @boxMenuExpanded="handleBoxMenuExpanded" @boxMenuCollapsed="handleBoxMenuCollapsed" @readMore="readMore" ref="boxMenu" :progress="progress" :checklist="checklist" :hotspotGroups="hotspotsCounterItems" />

            <on-board :progress-percent="progressPercent" @detailShowed="handleOnBoardDetailShowed" @detailHidden="handleOnBoardDetailHidden" ref="onBoard"></on-board>
        </div>

        <div id="controls" :class="controlsClassName" v-if="isLoggedIn">
            <div id="controls__setup" class="controls__block" :class="{ 'visible': controls.isVisible, 'toggled': controls.isToggled }">
                <div class="controls__inner">
                    <div class="controls__main">
                        <button class="btn-transparent" @click="toggleVRDisplay()" :title="$t('controls.toggleVRDisplay')" v-if="hasVRDisplay"><i class="icon-vr-view"></i> {{$t('controls.toggleVRDisplay')}}</button>
                        <button class="btn-transparent" @click="toggleGyroscope()" :title="$t('controls.toggleGyroscope')"><i class="icon-dots-grid"></i> {{$t('controls.toggleGyroscope')}}</button>
                        <button class="btn-transparent" @click="toggleFullScreen()" :title="$t('controls.toggleFullScreen')"><i class="icon-full-resize"></i> {{$t('controls.toggleFullScreen')}}</button>
                        <button class="btn-transparent" @click="toggleAutorotate()" :title="$t('controls.toggleAutorotate')"><i class="icon-crop-rotate"></i> {{$t('controls.toggleAutorotate')}}</button>
                        <language-select @beforeLanguageChange="hideHotspots()" @afterLanguageChange="afterLanguageChange" v-if="languageEnabled" ref="langSelect" />
                        <button class="btn-transparent" @click="resetSettings()" :title="$t('controls.resetSettings')"><i class="icon-rotate-counterclockwise"></i> {{$t('controls.resetSettings')}}</button>
                    </div>
                </div>
            </div>
            <div id="controls__nav">
                <button class="btn-transparent btn-toggle-ui" @click="toggleControls()" :title="$t('controls.toggleControls')"><i class="icon-controls"></i></button>
            </div>
        </div>

        <button @click="openHalfBox()" class="btn" :class="aboutButtonClassName" v-if="isLoggedIn && !aboutThisSectionHidden">
            <span class="question-mark">{{ $t('question-mark') }}</span>
            <span>{{$t('menu.about-this-section')}}</span>
        </button>

        <div class="half-box-wrapper" :class="{ 'visible': halfBox.isVisible, 'toggled': halfBox.isToggled }" v-if="isLoggedIn">
            <div class="half-box">
                <button class="btn-transparent close-button" @click="closeHalfBox()"><i class="icon-close-alt"></i></button>
                <button class="btn-transparent btn-prev"><i class="icon-left-arrow"></i></button>
                <div class="half-box__content" v-html="halfBoxContent"></div>
                <div class="half-box__gallery">
                    <div class="half-box__gallery-wrap">
                        <carousel :scrollPerPage="false">
                            <slide v-for="image in aboutThisSectionImages" :key="image.id">
                                <div class="slide-inner">
                                    <img :src="image" />
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>

        <div class="half-box-wrapper contact-form" :class="{ 'visible': contactForm.isVisible, 'toggled': contactForm.isToggled }" v-if="isLoggedIn">
            <div class="half-box">
                <button class="btn-transparent close-button" @click="closeContactForm()"><i class="icon-close"></i></button>
                <div class="half-box__content">
                    <h2 v-html="$t('get-in-touch')"></h2>

                    <p class="intro-text" v-html="$t('get-in-touch-intro-text')"></p>
                    <div class="contact-form-wrap">
                        <form id="contactForm">
                            <div class="form-row">
                                <input type="text" :placeholder="$t('form.name')" :class="{ 'error': contactForm.isInvalid('name') }" id="formName" v-model="contactForm.name" />
                                <input type="text" :placeholder="$t('form.email')" :class="{ 'error': contactForm.isInvalid('email') }" id="formEmail" v-model="contactForm.email" />
                            </div>
                            <div class="form-row">
                                <input type="text" :placeholder="$t('form.text')" :class="{ 'error': contactForm.isInvalid('text') }" id="formText" v-model="contactForm.text" />
                                <input type="text" :placeholder="$t('form.phone')" :class="{ 'error': contactForm.isInvalid('phone') }" id="formPhone" v-model="contactForm.phone" />
                            </div>
                            <div class="form-disclaimer" :class="{ 'error': contactForm.isInvalid('disclaimer'), 'checked': contactForm.disclaimer }">
                                <label for="contactFormDisclaimer"><input type="checkbox" id="contactFormDisclaimer" v-model="contactForm.disclaimer" /></label>
                                {{$t('form.disclaimer')}}
                            </div>

                            <div class="nav-line"><button class="btn-send" @click="sendContactForm($event)">{{$t('form.send')}}</button><a :href="$t('halfBox.link')" target="_blank">{{$t('halfBox.linkTitle')}}</a></div>

                            <div class="form-status-wrapper" v-if="contactForm.status">
                                <div class="form-status" :class="contactForm.statusClassName" v-html="contactForm.status"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="final-screen__overlay" :class="finalScreenClassName" v-if="finalScreen.wasDisplayed">
            <div class="final-screen__text">
                <h2>{{ $t('final-screen-title') }}</h2>
                <h3>{{ $t('final-screen-subtitle') }}</h3>
                <p>{{ $t('final-screen-text') }}</p>
                <a :href="$t('final-screen-link')" target="_blank">{{ $t('final-screen-link-text') }} <i class="icon-arrow-left"></i></a>
            </div>

            <div class="final-screen-app-title" @click="dismissFinalScreen()">{{ $t('final-screen-app') }} <span class="green-big-dot"></span></div>
        </div>

        <div class="intro-login___overlay" v-if="!isLoggedIn">
            <div class="intro-login__panel">
                <div class="intro-login__panel-inner">
                    <div class="intro-login__panel-header">
                        {{$t('login.title')}}<span class="green-dot">.</span>
                    </div>
                    <div class="intro-login__panel-form">
                        <div class="intro-login__text">
                            {{$t('login.instructions')}}
                        </div>
                        <div class="intro-login__panel-form__row" :class="userRowClassName">
                            <input type="text" id="login" :placeholder="$t('form.your_name_placeholder')" v-model="user" class="input-field" />
                        </div>
                        <div class="intro-login__panel-form__row" :class="passwordRowClassName">
                            <input type="password" id="password" :placeholder="$t('form.password_placeholder')" v-model="password" class="input-field" />
                        </div>
                        <div class="intro-login__panel-form__row" :class="{ 'preferred-selected': preferredLang , langSelRowClassName}">
                            <div class="language-selector input-field" :class="langSelectorClassName">
                                <div class="language-selector__select" @click="toggleLangSel()">
                                    <span v-if="!preferredLang">{{ $t('form.langSelectPlaceholder') }}</span>
                                    <span v-if="preferredLang">
                                        <span class="lang-flag"><img class="lang-icon" :src="require('@/assets/flag-' + preferredLang.code + '.svg')" :alt="preferredLang.langTitle" /></span> <span class="language-select__item-text">{{ preferredLang.langTitle }}</span>
                                    </span>
                                </div>

                                <div class="language-selector__items" :class="{ 'opened': langSelOpened }">
                                    <div class="language-selector__item" v-for="(lang, index) in languages" v-bind:key="index" @click="setPreferredLang(lang)">
                                        <span class="lang-flag"><img class="lang-icon" :src="require('@/assets/flag-' + lang.code + '.svg')" :alt="lang.langTitle" /></span> <span class="language-select__item-text">{{ lang.langTitle }}</span>                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="intro-login__panel-form__row error-row" v-if="loginError">
                            <strong v-if="loginError == 'missing_fields'">{{$t('form.missingFieldsError')}}</strong>
                            <strong v-if="loginError == 'bad_credentials'">{{$t('form.badCredentialsError')}}</strong>
                            <strong v-if="loginError == 'has_expired'">{{$t('form.hasExpiredError')}}</strong>
                        </div>
                    </div>
                    <div class="intro-login__panel-footer">
                        <button class="btn" :class="loginButonClassName" @click="login()">{{$t('form.login')}} <img src="@/assets/button-icon-arrow-white.svg" alt="&gt;" /></button>

                        <div class="intro-login__with-sso">
                            <span class="intro-login__with-sso-text">or login with Single Sign-On</span>
                        </div>

                        <button class="btn btn-outline" @click="loginWithSSO()">{{$t('form.loginSSO')}}</button>
                        <!--div class="intro-login__panel-form__row half-row">
                            <div class="half-col">
                                <button class="btn" :class="loginSubButtonClassName" @click="login">{{$t('form.trainee_access')}}</button>
                            </div>
                            <div class="half-col">
                                <button class="btn" :class="loginSubButtonClassName" @click="login">{{$t('form.administration')}}</button>
                            </div>
                        </div-->
                    </div>
                </div>
            </div>
        </div>

        <FullCoverLoader ref="loginLoader">{{$t('login.we_are_logging_you_in')}}<span>{{$t('login.wait_a_moment')}}</span></FullCoverLoader>

        <ContentModal ref="contentModal" :options="contentModalOptions" @wasRead="wasReadModal" v-if="isLoggedIn" @modalButtonTriggered="triggerModalButton" @wasClosed="onModalClosed" />

        <div v-if="isLoggedIn" class="popup-message" :class="popupMessageClassName" @click="hideMessage">{{popupMessage.text}} <i class="icon-close-alt"></i></div>
    </div>
</template>

<script>
    import CONFIG from './config.js'
    import appData from './AppData.json'
    import marzipano from './components/marzipano.vue'
    import boxMenu from './components/boxMenu.vue'
    import languageSelect from './components/languageSelect.vue'
    import FullCoverLoader from './components/FullCoverLoader.vue'
            /*import MyNotes from './components/myNotes.vue'*/
    import ContentModal from './components/ContentModal.vue'
    import Helpers from './helpers.js'
    import { Carousel, Slide } from 'vue-carousel';
    import 'vue-select/dist/vue-select.css';
    import ON_FEED_JSON from './data/Onfeed';
    import MY_JOURNEY_JSON from './data/MyJourney';
    import onBoard from "./components/onBoard";
    import Notifications from "./components/notifications";
    import NOTIFICATIONS_JSON from './data/Notification';
    import { PublicClientApplication } from '@azure/msal-browser';
    import users from './data/users';
    
    export default {
        name: 'app',
        components: {
            Notifications,
            onBoard,
            marzipano,
            boxMenu,
            languageSelect,
            FullCoverLoader,
            Carousel,
            Slide,
            ContentModal
        },
        computed: {
            roomMessage: function () {
                return this.$t('now-you-are-at') + ': <strong>' + this.currentRoom + '</strong>';
            },
            roomInfoTarget: function () {
                return '';
            },
            prevPage: function () {
                return '';
            },
            nextPage: function () {
                return '';
            },
            controlsClassName: function () {
                return 'controls-' + this.$root.$i18n.locale;
            },
            hotspotContentOverlayed: function () {
                var overClass = this.hotspotContentIsToggled ? 'hotspot-content-overlayed' : '';

                if (this.CBDisplayToggled) {
                    overClass += ' cb-displayed';
                }

                if (this.feedShowed) {
                    overClass += ' with-feed-detail-showed';
                }

                if (this.allFeedShowed) {
                    overClass += ' with-all-feed-showed';
                }

                if (this.onBoardDetailShowed) {
                    overClass += ' with-on-board-detail-showed';
                }

                if (this.boxMenuExpanded) {
                    overClass += ' with-box-menu-expanded';
                }

                if (this.notificationExpanded) {
                    overClass += ' with-notification-expanded';
                }

                if (this.notificationCollapse) {
                    overClass += ' with-notification-collapse';
                    this.removeNotificationCollapseClass();
                }

                return overClass;
            },
            finalScreenClassName: function () {
                var className = [];

                if (this.finalScreen.isToggled) {
                    className.push('toggled');
                }

                if (this.finalScreen.isVisible) {
                    className.push('visible');
                }

                return className.join(' ');
            },
            popupMessageClassName: function () {
                var className = [];

                if (this.popupMessage.isToggled) {
                    className.push('toggled');
                }

                if (this.popupMessage.isVisible) {
                    className.push('visible');
                }

                className.push(this.popupMessage.className);

                return className.join(' ');
            },
            sidebarsIcon: function () {
                let sidebarsIcon = 'icon-chevron-right';

                if (!this.sidebars.isToggled) {
                    sidebarsIcon = 'icon-chevron-left';
                }

                return sidebarsIcon;
            },
            sidebarsClassName: function () {
                let sidebarClassNameList = [];

                if (this.sidebars.isToggled) {
                    sidebarClassNameList.push('toggled');
                }

                if (this.sidebars.isVisible) {
                    sidebarClassNameList.push('visible');
                }

                return sidebarClassNameList.join(' ');
            },
            passwordRowClassName: function () {
                let pClassName = '';
                if (this.loginTriggered && (!this.password || this.password === '')) {
                    pClassName = 'error-field';
                }

                return pClassName;
            },
            userRowClassName: function () {
                let pClassName = '';
                if (this.loginTriggered && (!this.user || this.user === '')) {
                    pClassName = 'error-field';
                }

                return pClassName;
            },
            langSelRowClassName: function () {
                return '';
            },
            langSelectorClassName: function () {
                return '';
            }
        },
        methods: {
            triggerModalButton: function (data) {
                if (data.details && data.details.buttonTarget) {
                    window.open(data.details.buttonTarget);
                }
            },
            updateInteraction: function () {
                if (this.isLoggedIn && this.loggedInUser !== null && this.loggedInUser.sessionId) {
                    let expireTime = CONFIG.SESSION_MAX_TIME;
                    if (this.rememberMe) {
                        expireTime = CONFIG.REMEMBER_ME_TIME;
                    }

                    Helpers.setCookie('wb.token', this.loggedInUser.sessionId, expireTime);
                }
            },
            hideOtherModules: function (moduleToShow) {
                this.updateInteraction();

                let self = this;
                let delayed = false;
                this.modules.map(function (module) {
                    if (self.$refs[module.name] && self.$refs[module.name].isShowed() && module.name !== moduleToShow) {
                        self.$refs[module.name].hide();
                        delayed = true;
                    }
                });

                return delayed;
            },
            toggleSidebars: function () {
                Helpers.UI.dynamicToggle(this.sidebars);
                this.updateInteraction();
            },
            hideOverlayedComponents: function () {
                this.$refs.myNotes.hide();
                this.updateInteraction();
            },
            VRDisplayFound: function () {
                this.hasVRDisplay = true;
                this.updateInteraction();
            },
            CBViewLeaved: function () {
                this.CBDisplayToggled = false;
                this.updateInteraction();
            },
            toggleCBDisplay: function () {
                this.CBDisplayToggled = true;
                this.$refs.marzipano.toggleCBDisplay();
                this.updateInteraction();
            },
            toggleVRDisplay: function () {
                if (this.hasVRDisplay) {
                    this.$refs.marzipano.toggleVRDisplay();
                    this.updateInteraction();
                }
            },
            beforeMenuItemOpen: function () {
                this.hideHotspots();
                this.hideOpenedHotspot();
            },
            afterLanguageChange: function (langCode) {
                let self = this;
                self.languages.map(function (langElem) {
                    if (langElem.code == langCode) {
                        self.setPreferredLang(langElem);
                        self.triggerOnSceneLoadedHotspot();
                    }
                });
            },
            toggleLangSel: function () {
                this.langSelOpened = !this.langSelOpened;
            },
            setPreferredLang: function (val) {
                this.preferredLang = val;
                this.$root.$i18n.locale = val.code;
                this.langSelOpened = false;
                this.reloadScene();
            },
            hotspotVoiceOver: function (voiceOver) {
                this.playAudio(voiceOver);
            },
            moreLinkClicked: function (openForm) {
                if (openForm) {
                    this.openContactForm();
                }
            },
            //TODO: Rewrite to vuex router based
            updatePageView: function (title, pagePath) {
                let pageTitle = this.$t('site-title') + ' - ' + title;
                document.title = pageTitle;
                this.pushHistoryState(pageTitle, CONFIG.ROOT_PATH + pagePath);
                this.updateInteraction();
            },
            showMyNotes: function () {
                let moduleName = 'myNotes';
                let delayed = this.hideOtherModules(moduleName);
                let displayDelay = delayed ? CONFIG.HIDE_SHOW_DELAY : 0;
                let self = this;

                self.updatePageView(self.$t('mynotes.title'), 'my-notes');

                setTimeout(function () {
                    self.$refs.myNotes.show();
                }, displayDelay);
            },
            showMyProfile: function () {
                let moduleName = 'myProfile';
                let delayed = this.hideOtherModules(moduleName);
                let displayDelay = delayed ? CONFIG.HIDE_SHOW_DELAY : 0;
                let self = this;

                self.updatePageView(self.$t('profile.title'), 'my-profile');

                setTimeout(function () {
                    self.$refs.myProfile.show();
                }, displayDelay);
            },
            showMyDocs: function () {
                let moduleName = 'myDocs';
                let delayed = this.hideOtherModules(moduleName);
                let displayDelay = delayed ? CONFIG.HIDE_SHOW_DELAY : 0;
                let self = this;

                self.updatePageView(self.$t('mydocs.title'), 'my-docs');

                setTimeout(function () {
                    self.$refs.myDocs.show();
                }, displayDelay);
            },
            showMyOnboard: function () {
                let moduleName = 'myOnboard';
                let delayed = this.hideOtherModules(moduleName);

                /*
                 let displayDelay = delayed ? CONFIG.HIDE_SHOW_DELAY : 0;
                 let self = this;
                 
                 self.updatePageView(self.$t('onboard.title'), 'my-onboard');
                 
                 setTimeout(function () {
                 self.$refs.myOnboard.show();
                 }, displayDelay);*/
            },
            onModalClosed: function () {
                let self = this;
                if (this.progressPercent === 100 && !this.finalScreen.wasDisplayed && this.canShowEnd) {
                    setTimeout(function () {
                        Helpers.UI.dynamicToggle(self.finalScreen);
                        self.finalScreen.wasDisplayed = true;
                    }, 800);
                }

                this.hotspotContentIsToggled = false;
            },
            onModuleHidden: function () {
                this.updatePageView(this.currentSceneTitle, this.currentScenePath);

                this.activeModule = 'panoramas';
            },
            onMyNotesShowed: function () {
                this.activeModule = 'myNotes';
            },
            onMyProfileShowed: function () {
                this.activeModule = 'myProfile';
            },
            onMyDocsShowed: function () {
                this.activeModule = 'myDocs';
            },
            onMyOnboardShowed: function () {
                this.activeModule = 'myOnboard';
            },
            onRemoveNote: function (data) {
                this.updateInteraction();

                let self = this;
                let result = {
                    ok: false,
                    noteId: null
                };
                let formData = new FormData();
                formData.append('user', this.user);
                formData.append('id', data.id);
                formData.append('action', 'remove');

                self.axios.post(CONFIG.API_PATH + 'notes/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data; charset=utf-8'
                    }
                }).then(function (response) {
                    if (response.data.status !== 1) {
                        result.ok = false;
                        result.error = 'NOT_REMOVED';
                        result.message = 'note_not_removed';
                    } else {
                        result.ok = true;
                        result.message = 'note_removed';
                    }

                    data.callback(result);
                }).catch(function (error) {
                    result.ok = false;
                    result.error = error;

                    data.callback(result);
                });
            },
            onHighlightNote: function (data) {
                this.updateInteraction();

                let self = this;
                let result = {
                    ok: false,
                    noteId: null
                };
                let formData = new FormData();
                formData.append('user', this.user);
                formData.append('id', data.id);
                formData.append('action', 'highlight');

                self.axios.post(CONFIG.API_PATH + 'notes/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data; charset=utf-8'
                    }
                }).then(function (response) {
                    if (response.data.status !== 1) {
                        result.ok = false;
                        result.error = 'NOT_HIGHLIGHTED';
                    } else {
                        result.ok = true;
                    }

                    data.callback(result);
                }).catch(function (error) {
                    result.ok = false;
                    result.error = error;

                    data.callback(result);
                });
            },
            onSaveNote: function (data) {
                this.updateInteraction();

                let self = this;
                let result = {
                    ok: false,
                    noteId: null
                };

                if (!data.note && !data.callback) {
                    return;
                }

                if (!data.note && data.callback) {
                    result.error = 'not_defined';
                    data.callback(result);
                    return;
                }

                let formData = new FormData();
                for (var noteField in data.note) {
                    if (data.note.hasOwnProperty(noteField)) {
                        let value = data.note[noteField];

                        if (noteField === 'labels' || noteField === 'files') {
                            continue;
                        }

                        if (typeof (value) === 'object' && value.value) {
                            value = value.value;
                        }

                        formData.append(noteField, value);
                    }
                }

                let noteLabels = [];
                data.note.labels.map(function (label) {
                    noteLabels.push(label.value);
                });

                let noteFiles = [];
                data.note.files.map(function (file) {
                    let fileItem = {};
                    fileItem.title = file.title;
                    fileItem.fileId = file.fileId;

                    noteFiles.push(fileItem);
                });

                formData.append('labels', noteLabels.join(','));
                formData.append('files', JSON.stringify(noteFiles));

                //Append user
                formData.append('user', this.user);
                formData.append('action', 'save');

                self.axios.post(CONFIG.API_PATH + 'notes/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data; charset=utf-8'
                    }
                }).then(function (response) {
                    if (response.data.status !== 1) {
                        result.ok = false;
                        result.error = 'NOT_SAVED';
                        result.message = 'note_not_saved';
                    } else {
                        result.ok = true;
                        result.noteId = response.data.noteId;
                        result.message = 'note_saved';
                    }

                    data.callback(result);
                }).catch(function (error) {
                    result.ok = false;
                    result.error = error;

                    data.callback(result);
                });
            },
            onLoadNotes: function (callback, search) {
                this.updateInteraction();

                search = search || false;

                let result = {
                    ok: false,
                    notes: null
                };

                let formData = new FormData();
                formData.append('user', this.user);
                formData.append('search', search);
                formData.append('action', 'load');

                this.axios.post(CONFIG.API_PATH + 'notes/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data; charset=utf-8'
                    }
                }).then(function (response) {
                    if (!response.data) {
                        result.ok = false;
                        result.error = 'NOT_LOADED';
                    } else {
                        result.ok = true;
                        result.notes = response.data;
                    }

                    callback(result);
                }).catch(function (error) {
                    result.ok = false;
                    result.error = error;

                    callback(result);
                });
            },
            showMessage: function (message, type) {
                this.popupMessage.text = message;
                this.popupMessage.className = 'message-' + type;

                Helpers.UI.dynamicToggle(this.popupMessage);
                this.updateInteraction();
            },
            debugMode: function (onDebugValidated) {
                //TODO:
                //Rewrite to check if debug is enabled
                //and possible
                onDebugValidated(this);
            },
            debugProgressComplete: function () {
                this.debugMode(function (context) {
                    context._updateProgressValues(true);
                });
            },
            debugProgressReset: function () {
                this.debugMode(function (context) {
                    context._updateProgressValues(false);
                });
            },
            _updateProgressValues: function (value) {
                let self = this;

                let progress = self.progress;

                Object.keys(progress.hotspots).map(function (hs) {
                    if (progress.hotspots.hasOwnProperty(hs)) {
                        progress.hotspots[hs] = value;
                    }
                });

                Object.keys(progress.scenes).map(function (sc) {
                    if (progress.scenes.hasOwnProperty(sc)) {
                        progress.scenes[sc] = value;
                    }
                });

                self.updateProgress(progress);
            },
            updateProgress: function (progress) {
                this.progress = progress;

                let hotspotsCount = Object.keys(progress.hotspots).length;
                let scenesCount = Object.keys(progress.scenes).length;

                let hotspotsPassed = 0;
                Object.keys(progress.hotspots).map(function (hs) {
                    if (progress.hotspots.hasOwnProperty(hs) && progress.hotspots[hs]) {
                        hotspotsPassed++;
                    }
                });

                let scenesPassed = 0;
                Object.keys(progress.scenes).map(function (sc) {
                    if (progress.scenes.hasOwnProperty(sc) && progress.scenes[sc]) {
                        scenesPassed++;
                    }
                });

                let progressPercent = (Math.ceil((hotspotsPassed + scenesPassed) * 1000 / (hotspotsCount + scenesCount)) / 10);

                this.progressPercent = progressPercent;

                if (this.loggedInUser && this.loggedInUser.account) {
                    console.log('DLT: Store progress for ' + this.loggedInUser.account);
                    localStorage.setItem('com.dlt/vt/' + this.loggedInUser.account, JSON.stringify(progress));
                }

                this.$refs.boxMenu.$forceUpdate();
                this.updateInteraction();
            },
            dismissFinalScreen: function () {
                Helpers.UI.dynamicToggle(this.finalScreen, null, true);
            },
            triggerOnSceneLoadedHotspot: function () {
                if (this.onSceneLoaded[this.currentSceneId]) {
                    this.hideOpenedHotspot();
                    setTimeout(() => {
                        const triggerHotspot = this.onSceneLoaded[this.currentSceneId];
                        if (triggerHotspot.target) {
                            this.toggleHotspotContent(triggerHotspot.target);
                            this.$refs.marzipano.toggleContentFromParent(triggerHotspot.target.wrapperId);
                        }
                    }, 500)
                }
            },
            sceneChange: function (scene) {
                this.currentSceneId = scene.sceneId;
                this.currentSceneKey = scene.info.sceneKey;
                this.displayedScenes[scene.info.sceneKey] = true;

                this.passedScenes[scene.info.group] = true;

                var locale = this.$root.$i18n.locale;

                var sceneGroup = scene.info.group || false;
                var sceneId = scene.sceneId;
                var sceneGroupContent = scene.info.content;
                var sceneGroupTitle = scene.info.title;

                if (scene.info.voiceOver && this.isLoggedIn) {
                    this.playAudio(scene.info.voiceOver);
                }

                if (this.onSceneLoaded[sceneId]) {
                    var triggerHotspot = this.onSceneLoaded[sceneId];
                    if (triggerHotspot.target && !triggerHotspot.wasTriggered) {
                        this.toggleHotspotContent(triggerHotspot.target);
                        this.$refs.marzipano.toggleContentFromParent(triggerHotspot.target.wrapperId);
                        this.onSceneLoaded[sceneId].wasTriggered = true;
                    }
                }

                if (scene.info.didYouKnow && scene.info.didYouKnow[locale]) {
                    this.$refs.onBoard.setCurrentDidYouKnow(scene.info.didYouKnow[locale]);
                }

                var menuItem = null;
                var menuSubItem = null;

                //Find menu item
                MY_JOURNEY_JSON.menu.map(function (item) {
                    if (item.id !== sceneGroup) {
                        return;
                    }

                    menuItem = item;
                    //sceneGroupContent = item.content;
                    //sceneGroupTitle = item.title;

                    if (item.children) {
                        item.children.map(function (subitem) {
                            if (subitem.sceneId !== sceneId) {
                                return;
                            }

                            menuSubItem = subitem;
                        });
                    }
                });

                if (menuItem) {
                    this.$refs.boxMenu.activeMenuItem = menuItem.link[locale];
                    this.$refs.boxMenu.currentMenuItem = menuItem;
                    this.$refs.boxMenu.subitems = false;
                }
                if (menuSubItem && !menuSubItem.hide) {
                    this.$refs.boxMenu.showSubmenu = !menuItem.singleMenuItem;
                    this.$refs.boxMenu.activeMenuSubItem = menuSubItem.link[locale];

                    //Filter and sort subitems
                    var filteredChildren = [];
                    menuItem.children.map(function (subitem) {
                        if (!subitem.hide) {
                            filteredChildren.push(subitem);
                        }
                    });

                    //Sort menu items
                    filteredChildren.sort(function (a, b) {
                        return (a.order > b.order) ? 1 : -1;
                    });

                    this.$refs.boxMenu.subitems = filteredChildren;
                }

                //Assign room / scene group info
                this.currentRoom = sceneGroupTitle[locale];
                this.currentSceneNavKey = scene.info.sceneNavKey;
                this.halfBoxContent = sceneGroupContent[locale] || '';
                this.aboutThisSectionImages = scene.gallery;
                this.aboutThisSectionHidden = scene.info.aboutButtonHidden || false;

                var otherInfoDisabled = menuItem.otherInfoDisabled || false;
                this.$refs.boxMenu.setOtherInfoDisabled(otherInfoDisabled);
                if (this.halfBoxContent.indexOf('<h2>') < 0) {
                    this.halfBoxContent = '<div class="half-box__context-header"> <div class="half-box__context-header-icon"><i class="' + menuItem.icon + '"></i></div>' + '<h2 class="half-box__context-header-title">' + this.currentRoom + '</h2></div><p>' + this.halfBoxContent + '</p>';
                }

                var hasForm = false;

                //TODO: Rewrite to other URL's
                if (!hasForm && !scene.info.hideDismissButton) {
                    var dismissTitle = this.$t('lets-get-started');
                    if (scene.info.dismissTitle) {
                        dismissTitle = scene.info.dismissTitle[locale] || scene.info.dismissTitle;
                    }

                    var dismissEvent = 'closeHalfBox()';
                    if (scene.info.dismissEvent) {
                        dismissEvent = scene.info.dismissEvent;
                    }

                    this.halfBoxContent += "<div class=\"nav-line\"><button class=\"btn btn-primary\" onclick=\"" + dismissEvent + "\">" + dismissTitle + "</button></div>";
                }

                //Trigger changed URL
                this.currentSceneTitle = scene.info.title[locale];
                this.currentScenePath = scene.info.link[locale];
                this.currentSceneInfo = scene.info;

                this.updatePageView(this.currentSceneTitle, this.currentScenePath);
                this.updateInteraction();
            },
            toggleHotspotContent: function (hotspot) {

                let locale = this.$root.$i18n.locale;
                let type = hotspot.details.type || 'default';
                let modalName = hotspot.wrapperId || 'default';

                let videoId = hotspot.details.videoId;
                if (typeof (videoId) == 'object' && videoId[locale]) {
                    videoId = videoId[locale];
                }
                this.contentModalOptions.videoId = videoId;
                this.contentModalOptions.videoPoster = hotspot.details.videoPoster || '';
                this.contentModalOptions.videoType = hotspot.details.videoType || null;
                this.contentModalOptions.useAltVideoPlayer = hotspot.details.useAltVideoPlayer || false;
                this.contentModalOptions.title = hotspot.details.title[locale];
                this.contentModalOptions.content = hotspot.details.content[locale];
                this.contentModalOptions.hasFooter = !hotspot.details.videoId || false;
                if (hotspot.details.hideFooter) {
                    this.contentModalOptions.hasFooter = false;
                }
                this.contentModalOptions.addClass = hotspot.details.addClass || '';
                this.contentModalOptions.image = hotspot.details.image || '';
                this.contentModalOptions.form = hotspot.details.form || null;
                this.contentModalOptions.isMustRead = hotspot.details.isMustRead || false;
                this.contentModalOptions.wasRead = this.mustReadModals[modalName] || false;
                if (hotspot.details.buttonTitle && hotspot.details.buttonTitle[locale]) {
                    this.contentModalOptions.buttonTitle = hotspot.details.buttonTitle[locale];
                }
                this.contentModalOptions.hideBottomButton = hotspot.details.hideBottomButton || false;
                this.contentModalOptions.modalName = modalName;
                this.contentModalOptions.type = !hotspot.details.videoId ? type : 'youtube';
                this.contentModalOptions.showText = hotspot.details.showText;
                this.contentModalOptions.sourceContent = hotspot || null;
                this.contentModalOptions.hasCheckedSign = true;
                if (hotspot.details.hideCheckedSign) {
                    this.contentModalOptions.hasCheckedSign = false;
                }
                this.contentModalOptions.saveToNote = false;
                this.contentModalOptions.invisibleHotspot = hotspot.details.invisibleHotspot || false;
                this.contentModalOptions.onloadTrigger = hotspot.details.onloadTrigger || false;
                this.contentModalOptions.hidePlayerCover = hotspot.details.hidePlayerCover || false;

                setTimeout(() => {
                    this.$refs.contentModal.updateYTPlayer();
                }, 100);

                this.$refs.contentModal.toggle();
                this.updateInteraction();
            },
            hideHotspots: function () {
                this.$refs.marzipano.hideHotspots();
                this.updateInteraction();
            },
            hideOpenedHotspot: function () {
                this.$refs.contentModal.close();
                this.updateInteraction();
            },
            reloadScene: function () {
                if (this.$refs.marzipano) {
                    this.$refs.marzipano.switchSceneByKey(this.currentSceneKey);
                }

                this.updateInteraction();
            },
            toggleGyroscope: function () {
                this.$refs.marzipano.toggleGyroscope();
                this.updateInteraction();
            },
            toggleControls: function () {
                Helpers.UI.dynamicToggle(this.controls);
                this.updateInteraction();
            },
            toggleAutorotate: function () {
                this.$refs.marzipano.toggleAutorotate();
                this.updateInteraction();
            },
            toggleFullScreen: function () {
                this.$refs.marzipano.toggleFullScreen();
                this.updateInteraction();
            },
            resetSettings: function () {
                this.$refs.marzipano.resetSettings();
                this.updateInteraction();
            },
            hotspotContentToggled: function (hotspotContentVisibility) {
                this.hotspotContentIsToggled = hotspotContentVisibility;
                this.updateInteraction();
            },
            contentModalHidden: function () {
                this.hotspotContentIsToggled = false;
            },
            menuItemOpened: function (menuItem) {
                if (menuItem.sceneKey >= 0) {
                    this.$refs.marzipano.switchSceneByKey(menuItem.sceneKey);
                    this.updateInteraction();
                }

                this.$refs.boxMenu.$forceUpdate();
            },
            openLinkValidate: function (sceneKey, passedChecklistGroup) {
                if (!sceneKey && !passedChecklistGroup) {
                    this.$refs.marzipano.openLink(0);
                }

                if (!this.hasDoneChecklistGroup(passedChecklistGroup)) {
                    this.contentModalOptions = Helpers.clone(CONFIG.CONTENT_MODAL_DEFAULTS);
                    this.contentModalOptions.content = this.$t('passAllTheHotspots');
                    this.contentModalOptions.hasCheckedSign = false;
                    this.contentModalOptions.saveToNote = false;
                    this.contentModalOptions.type = 'alert';
                    this.$refs.contentModal.removeYTPlayer();
                    this.$refs.contentModal.toggle();
                } else {
                    this.$refs.marzipano.openLink(sceneKey);
                }
            },
            switchSceneByName: function (sceneName) {
                this.$refs.marzipano.switchSceneByName(sceneName);
            },
            openHalfBox: function () {
                Helpers.UI.dynamicToggle(this.halfBox);
                this.updateInteraction();
            },
            closeHalfBox: function () {
                Helpers.UI.dynamicToggle(this.halfBox, null, true);
                this.updateInteraction();
            },
            hideMessage: function () {
                Helpers.UI.dynamicToggle(this.popupMessage, null, true);
                this.updateInteraction();
            },
            openContactForm: function () {
                Helpers.UI.dynamicToggle(this.contactForm);
                this.updateInteraction();
            },
            closeContactForm: function () {
                Helpers.UI.dynamicToggle(this.contactForm, null, true);

                this.contactForm.resetForm(true);
                this.updateInteraction();
            },
            sendContactForm: function ($event) {
                $event.preventDefault();

                this.updateInteraction();
                this.contactForm.wasSendClicked = true;

                if (!this.contactForm.isValid()) {
                    this.contactForm.status = this.$t('form.error');
                    this.contactForm.statusClassName = 'error';
                    return false;
                } else {
                    this.contactForm.send();
                }

                return false;
            },
            pushHistoryState: function (urlTitle, url) {
                urlTitle = urlTitle || this.$t('site-title');
                url = url || CONFIG.ROOT_PATH;

                if (this.debugEnabled) {
                    url += '#debug:enabled';
                }

                if (!history.state || history.state.page != url) {
                    window.history.pushState({
                        action: 'view',
                        target: url,
                        page: url
                    }, urlTitle, url);
                }
            },
            navigate: function (dir, event) {
                event.preventDefault();

                var sceneKeyDir = dir !== 'next' ? -1 : 1;
                var navigateToSceneKey = this.currentSceneNavKey + sceneKeyDir;
                var sceneKeyLast = Object.keys(this.sceneNavKeys).length;

                if (navigateToSceneKey < 1) {
                    navigateToSceneKey = sceneKeyLast;
                }

                if (navigateToSceneKey > sceneKeyLast) {
                    navigateToSceneKey = 1;
                }

                this.$refs.marzipano.switchSceneByKey(this.sceneNavKeys[navigateToSceneKey]);
                this.updateInteraction();
            },
            validateLoggedInSSO() {
                const loginRequest = {
                    scopes: ["User.Read"]
                }

                let hash = location.hash;
                let accountId = "";
                let self = this;
                const myMsal = this.$msalInstance;
                const currentAccounts = myMsal.getAllAccounts();

                if (hash.indexOf('code') > 0 && currentAccounts.length === 0) {
                    this.$refs.loginLoader.toggle(true);
                    myMsal.handleRedirectPromise().then((response) => {
                        if (response !== null) {
                            accountId = response.account.homeAccountId;
                            // Display signed-in user content, call API, etc.
                        } else {
                            // In case multiple accounts exist, you can select
                            const currentAccounts = myMsal.getAllAccounts();

                            if (currentAccounts.length === 0) {
                                // no accounts signed-in, attempt to sign a user in
                                myMsal.loginRedirect(loginRequest);
                            } else if (currentAccounts.length > 1) {
                                // Add choose account code here
                            } else if (currentAccounts.length === 1) {
                                accountId = currentAccounts[0].homeAccountId;
                            }
                        }

                        self.login(accountId);
                    });
                }

                if (currentAccounts.length > 0) {
                    this.$refs.loginLoader.toggle(true);
                    accountId = currentAccounts[0].homeAccountId;
                }

                if (accountId !== "") {
                    self.login(accountId);
                }
            },
            loginWithSSO() {
                const loginRequest = {
                    scopes: ["User.Read"]
                }

                let accountId = "";
                let self = this;
                const myMsal = this.$msalInstance;
                myMsal.handleRedirectPromise().then((response) => {
                    if (response !== null) {
                        accountId = response.account.homeAccountId;
                        // Display signed-in user content, call API, etc.
                    } else {
                        // In case multiple accounts exist, you can select
                        const currentAccounts = myMsal.getAllAccounts();

                        if (currentAccounts.length === 0) {
                            // no accounts signed-in, attempt to sign a user in
                            myMsal.loginRedirect(loginRequest);
                        } else if (currentAccounts.length > 1) {
                            // Add choose account code here
                        } else if (currentAccounts.length === 1) {
                            accountId = currentAccounts[0].homeAccountId;
                        }
                    }

                    if (accountId !== "") {
                        self.login(accountId);
                    }
                });
            },
            login: function (forcedAccount) {
                forcedAccount = forcedAccount || false;

                let self = this;

                if (forcedAccount) {
                    let lastLogin = new Date();
                    self.lastLogin = lastLogin;

                    let data = {
                        lastLogin: lastLogin,
                        sessionId: 'wbDm' + lastLogin.getTime(),
                        account: forcedAccount
                    };
                    self.setLoggedIn(data);
                    return;
                }

                let missingFields = [];
                self.loginError = false;
                self.loginTriggered = true;

                if (!self.user || self.user === '') {
                    missingFields.push('user');
                }

                if (!self.password || self.password === '') {
                    missingFields.push('password');
                }

                if (missingFields.length > 0) {
                    self.loginError = 'missing_fields';
                    return;
                }

                let formData = new FormData();
                formData.append("action", "login");
                formData.append("user", self.user);
                formData.append("password", self.password);
                formData.append("remember", self.rememberMe);
                formData.append("host", location.href);

                self.$refs.loginLoader.toggle();

                let us = btoa(btoa(self.user) + '' + atob(CONFIG.ENABLE_STRING));
                let uh = btoa(CONFIG.ENABLE_STRING + '' + btoa(self.password));
                let credentialsMatch = false;
                users.map((uo) => {
                    if (us !== uo.u) {
                        return;
                    }

                    if (uh !== uo.h) {
                        return;
                    }

                    credentialsMatch = true;
                });

                if (!credentialsMatch) {
                    setTimeout(() => {
                        self.loginError = 'bad_credentials';
                        self.$refs.loginLoader.toggle();
                    }, 1000);
                    return;
                }

                let lastLogin = new Date();
                self.lastLogin = lastLogin;

                let data = {
                    lastLogin: lastLogin,
                    account: self.user,
                    sessionId: 'wbDm' + lastLogin.getTime()
                };
                self.setLoggedIn(data);
            },
            setLoggedIn: function (data) {
                let self = this;
                setTimeout(() => {
                    self.loggedInUser = {
                        loggedIn: true,
                        user: 'ccs@csc.cz',
                        name: 'Test Test',
                        sessionId: data.sessionId,
                        account: data.account,
                        loggedInTime: data.lastLogin,
                        lastInteraction: data.lastLogin,
                        loginCount: 999
                    };

                    let expireTime = CONFIG.SESSION_MAX_TIME;
                    Helpers.setCookie('wb.token', self.loggedInUser.sessionId, expireTime);

                    self.loginError = false;
                    self.isLoggedIn = true;

                    if (self.loggedInUser.account) {
                        let storedProgress = localStorage.getItem('com.dlt/vt/' + self.loggedInUser.account);
                        if (storedProgress) {
                            console.log('DLT: Loading progress from storage for ' + self.loggedInUser.account);
                            self.afterLoginProgressFetch(storedProgress);
                        }
                    }

                    if (!data.hideLoader) {
                        self.$refs.loginLoader.toggle();
                    }
                }, 500);
            },
            logout: function () {
                Helpers.setCookie('wb.token', null, -24);

                this.loggedInUser = null;
                this.isLoggedIn = null;
                this.loginTriggered = false;
                this.lastLogin = null;
                this.user = null;
                this.password = null;
                clearInterval(this.loggedInSessionCheckInterval);
                this.loggedInSessionCheckInterval = null;

                for (let lsKey in localStorage) {
                    if (localStorage.hasOwnProperty(lsKey) && lsKey.indexOf('com.dlt/vt') < 0) {
                        localStorage.removeItem(lsKey);
                    }
                }
                
                location.href = '/';
            },
            afterLoginProgressFetch: function (progressData) {
                let self = this;

                setTimeout(() => {
                    progressData = JSON.parse(progressData);

                    let hotspotsPassedForScenes = {};
                    for (let hsWrapperId in progressData.hotspots) {
                        let wasRead = progressData.hotspots[hsWrapperId] || false;
                        self.mustReadModals[hsWrapperId] = wasRead;
                        let hsSceneId = hsWrapperId.split('_')[1];
                        if (!hotspotsPassedForScenes[hsSceneId]) {
                            hotspotsPassedForScenes[hsSceneId] = 0;
                        }

                        if (wasRead) {
                            hotspotsPassedForScenes[hsSceneId]++;
                        }
                    }

                    for (let menuItemKey in self.menuData) {
                        let menuItem = self.menuData[menuItemKey];
                        let hsSceneId = menuItem.sceneId.split('_')[1];
                        if (hotspotsPassedForScenes[hsSceneId]) {
                            self.menuData[menuItemKey].hotspotsPassed = hotspotsPassedForScenes[hsSceneId];
                        }
                    }

                    for (let sceneId in progressData.scenes) {
                        let scenePassed = progressData.scenes[sceneId] || false;
                        self.passedScenes[sceneId] = scenePassed;
                        self.displayedScenes[self.sceneKeys[sceneId]] = scenePassed;
                    }

                    self.$refs.marzipano.updateProgressFromStored(progressData);
                    self.$refs.boxMenu.$forceUpdate();

                    if (this.progressPercent >= 100) {
                        this.canShowEnd = false;
                    }
                }, 500);
            },
            handleAllFeedShowed() {
                this.updateInteraction();
                this.allFeedShowed = true;

                if (this.boxMenuExpanded) {
                    this.$refs.boxMenu.collapse();
                }
                if (this.onBoardDetailShowed) {
                    this.$refs.onBoard.collapse();
                }
                if (this.notificationExpanded) {
                    this.afterNotificationCollapsed();
                    this.$refs.notificationMenu.collapse();
                }
            },
            handleAllFeedHidden() {
                this.updateInteraction();
                this.allFeedShowed = false;
                if (this.isAllBlocksCollapsed()) {
                    this.$refs.boxMenu.expand();
                }
            },
            handleOnBoardDetailShowed() {
                this.updateInteraction();
                this.onBoardDetailShowed = true;
            },
            handleOnBoardDetailHidden() {
                this.updateInteraction();
                this.onBoardDetailShowed = false;
            },
            handleBoxMenuExpanded() {
                this.updateInteraction();
                this.boxMenuExpanded = true;
            },
            handleBoxMenuCollapsed() {
                this.updateInteraction();
                this.boxMenuExpanded = false;
            },
            toggleNotifications() {
                this.updateInteraction();
                this.afterNotificationCollapsed();
                this.notificationExpanded = !this.notificationExpanded;
                if (this.notificationExpanded) {
                    this.handleNotificationExpanded();
                } else {
                    this.handleNotificationMenuCollapsed()
                }
            },
            handleNotificationExpanded() {
                this.updateInteraction();
                if (this.onBoardDetailShowed) {
                    this.$refs.onBoard.collapse();
                }
                if (this.boxMenuExpanded) {
                    this.$refs.boxMenu.collapse();
                }
            },
            handleNotificationMenuCollapsed() {
                this.updateInteraction();
                this.afterNotificationCollapsed();
                this.notificationExpanded = false;
                if (this.isAllBlocksCollapsed()) {
                    this.$refs.boxMenu.expand();
                }
            },
            isAllBlocksCollapsed() {
                return !this.onBoardDetailShowed
                        && !this.boxMenuExpanded;
            },
            readMore() {
                this.openHalfBox();
            },
            afterNotificationCollapsed() {
                if (this.notificationExpanded) {
                    this.notificationCollapse = true;
                }
            },
            removeNotificationCollapseClass() {
                setTimeout(() => {
                    this.notificationCollapse = false;
                }, 550);
            },
            getModuleLinkClassName: function (module) {
                if (this.activeModule === module.name) {
                    return 'active';
                }

                return '';
            },
            playAudio: function (path) {
                if (!CONFIG.PLAY_AUDIO) {
                    return false;
                }

                if (!this.playedAudios[path]) {
                    let self = this;

                    if (self.currentAudioPlaying && !self.currentAudioPlaying.ended) {
                        self.playedAudios[self.currentAudioPlayingPath] = false;
                        self.currentAudioPlaying.pause();
                    }

                    let audio = new Audio(path);

                    //Play sound
                    audio.play();

                    self.currentAudioPlaying = audio;
                    self.currentAudioPlayingPath = path;
                    self.playedAudios[path] = true;
                }
            },
            hasDoneChecklistGroup: function (nr) {
                return Helpers.hasDoneChecklistGroup(nr, this.checklist, this.progress);
            },
            wasReadModal: function (modalName) {
                this.mustReadModals[modalName] = true;
            }
        },
        async created() {
            let domain = location.hostname;
            let MSALConfig = CONFIG.MSAL[domain] || CONFIG.MSAL[CONFIG.MSALDefaultDomain];

            this.$msalInstance = new PublicClientApplication(MSALConfig);
        },
        mounted() {
            let self = this;
            window.closeHalfBox = this.closeHalfBox;
            window.switchSceneByName = this.switchSceneByName;
            window.WB = window.WB || {};
            window.WB.version = CONFIG.VERSION;

            if (this.debugEnabled) {
                window.WB.debug = {
                    progressComplete: self.debugProgressComplete,
                    progressReset: self.debugProgressReset
                };
            }
            
            this.validateLoggedInSSO();

            if (!this.isLoggedIn && location.pathname !== '/') {
                location.href = '/';
                return;
            }
        },
        data() {
            this.$root.$i18n.locale = CONFIG.DEFAULT_LOCALE;

            let languages = [];
            let langMessages = this.$root.$i18n.messages;
            Object.keys(langMessages).map(function (langCode) {
                languages.push({
                    code: langCode,
                    langTitle: langMessages[langCode].languageTitle || langCode
                });
            });

            let preparedHotspots = [];
            let preparedLinks = [];

            let sceneLocations = {};
            let sceneKeys = {};
            let sceneNavKeys = {};
            let checklist = {};
            let mustReadModals = {};
            let onSceneLoaded = {};
            let displayedScenes = {};
            let hotspotsCounterItems = {};

            appData.scenes.map(function (scene) {
                let sceneId = scene.sceneId;

                sceneLocations[scene.id] = scene.info.title;
                sceneKeys[scene.id] = scene.info.sceneKey;
                sceneNavKeys[scene.info.sceneNavKey] = scene.info.sceneKey;
                displayedScenes[scene.info.sceneKey] = false;

                if (scene.checklistGroup) {
                    if (!checklist[scene.checklistGroup]) {
                        checklist[scene.checklistGroup] = [];
                    }

                    checklist[scene.checklistGroup].push(scene.id);
                }

                scene.infoHotspots.map(function (infoHotspot) {
                    infoHotspot.sceneId = sceneId;

                    if (infoHotspot.details.checklistGroup) {
                        if (!checklist[infoHotspot.details.checklistGroup]) {
                            checklist[infoHotspot.details.checklistGroup] = [];
                        }
                        checklist[infoHotspot.details.checklistGroup].push(infoHotspot.wrapperId);
                    }

                    if (infoHotspot.details.isMustRead) {
                        mustReadModals[infoHotspot.wrapperId] = false;
                    }

                    if (infoHotspot.details.onloadTrigger) {
                        onSceneLoaded[sceneId] = {
                            target: infoHotspot,
                            wasTriggered: false
                        };
                    }

                    if (infoHotspot.hotspotsMenuGroup) {
                        if (!hotspotsCounterItems[infoHotspot.hotspotsMenuGroup]) {
                            hotspotsCounterItems[infoHotspot.hotspotsMenuGroup] = {};
                        }

                        hotspotsCounterItems[infoHotspot.hotspotsMenuGroup][infoHotspot.wrapperId] = true;
                    }

                    preparedHotspots.push(infoHotspot);
                });

                scene.linkHotspots.map(function (linkHotspot) {
                    let linkId = parseInt(preparedLinks.length.toString(), 10);
                    linkId++;

                    linkHotspot.sceneId = sceneId;
                    linkHotspot.isLinkHotspot = true;
                    linkHotspot.wrapperId = 'link_' + linkId;

                    preparedLinks.push(linkHotspot);
                });
            });

            //Remap location title to prepared link
            preparedLinks.map(function (link) {
                link.location = sceneLocations[link.target];
                link.targetSceneKey = sceneKeys[link.target];
                return link;
            });

            let dataP = this;

            return {
                aboutThisSectionImages: [],
                aboutThisSectionHidden: false,
                feedShowed: false,
                allFeedShowed: false,
                onBoardDetailShowed: false,
                boxMenuExpanded: true,
                notificationExpanded: false,
                notificationCollapse: false,
                countdown: '12 days, 3 hours, 34 min',
                isLoggedIn: false,
                accessToken: null,
                account: null, //msal account instance
                user: null,
                password: null,
                loginError: false,
                hasNavControls: false,
                languages: languages,
                langSelOpened: false,
                preferredLang: null,
                onSceneLoaded: onSceneLoaded,
                hotspotsCounterItems: hotspotsCounterItems,
                isFinished: false,
                sceneKeys: sceneKeys,
                loginErrors: {
                    901: 'bad_credentials',
                    902: 'has_expired',
                    903: 'not_logged_in'
                },
                loggedInSessionCheckInterval: null,
                loginTriggered: false,
                playedAudios: {},
                passedScenes: {},
                displayedScenes: displayedScenes,
                progress: {},
                loggedInUser: null,
                lastLogin: null,
                currentAudioPlaying: null,
                currentAudioPlayingPath: null,
                checklist: checklist,
                mustReadModals: mustReadModals,
                contentModalOptions: Helpers.clone(CONFIG.CONTENT_MODAL_DEFAULTS),
                finalScreen: {
                    isToggled: false,
                    isVisible: false,
                    wasDisplayed: false
                },
                sidebars: {
                    isToggled: true,
                    isVisible: true
                },
                halfBox: {
                    isToggled: false,
                    isVisible: false
                },
                popupMessage: {
                    text: '',
                    type: 'default',
                    isToggled: false,
                    isVisible: false
                },
                rememberMe: false,
                feedItems: ON_FEED_JSON.posts,
                contactForm: {
                    isToggled: false,
                    isVisible: false,
                    name: '',
                    phone: '',
                    email: '',
                    text: '',
                    disclaimer: false,
                    wasSendClicked: false,
                    status: false,
                    statusClassName: '',
                    errorContent: '',
                    sendData: [
                        'name', 'phone', 'email', 'text'
                    ],
                    validateEmail: function (email) {
                        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(email);
                    },
                    isInvalid: function (field) {
                        var isInvalid = false;

                        if (!this.wasSendClicked) {
                            return false;
                        }

                        if (field === 'disclaimer') {
                            return !this.disclaimer;
                        }

                        if (this[field] === '') {
                            isInvalid = true;
                        }

                        if (field === 'email' && !this.validateEmail(this[field])) {
                            isInvalid = true;
                        }

                        return isInvalid;
                    },
                    isValid: function () {
                        var allValid = true;
                        var self = this;
                        this.sendData.map(function (field) {
                            if (self.isInvalid(field)) {
                                allValid = false;
                            }
                        });

                        return allValid;
                    },
                    resetForm: function (resetStatus) {
                        var self = this;
                        self.sendData.map(function (field) {
                            self[field] = '';
                        });
                        self.disclaimer = false;
                        self.wasSendClicked = false;

                        resetStatus = resetStatus || false;
                        if (resetStatus) {
                            self.status = '';
                            self.statusClassName = '';
                        }
                    },
                    send: function () {
                        this.status = dataP.$t('form.sending');
                        this.statusClassName = '';

                        var inquiryContents = 'New Get in touch inquiry was sent from the web:<br /><ul>';
                        var self = this;
                        this.sendData.map(function (field) {
                            inquiryContents += '<li>' + dataP.$t('form.' + field) + ': ' + self[field] + '</li>';
                        });

                        inquiryContents += '</ul>';
                        inquiryContents += dataP.$t('form.disclaimerTitle') + ': ' + (self.disclaimer ? 'yes' : 'no');
                        inquiryContents += '<br /><br />This was sent automatically from VR Tour Contact Form';
                        var formData = new FormData();
                        formData.append('inquiryContents', inquiryContents);
                        formData.append('target', this.email);

                        var config = {headers: {'Content-Type': 'multipart/form-data'}};

                        dataP.axios.post(CONFIG.ROOT_PATH + 'api/', formData, config)
                                .then(function (response) {
                                    var result = response.data;
                                    if (result.inquirySent) {
                                        //self.inquiryWasSent = true;
                                        //self.resetValues();
                                        //self.$emit('resetValues');
                                        self.status = dataP.$t('form.sent');
                                        self.statusClassName = 'success';

                                        self.resetForm();
                                    } else {
                                        self.status = dataP.$t('form.sentError');
                                        self.statusClassName = 'error';
                                    }
                                })
                                .catch(function (error) {
                                    self.status = dataP.$t('form.sentError');
                                    self.statusClassName = 'error';
                                    self.errorContent = error;
                                });
                    }
                },
                halfBoxContent: '',
                currentSceneTitle: null,
                currentScenePath: null,
                currentSceneInfo: null,
                currentRoom: null,
                currentSceneId: null,
                currentSceneKey: null,
                currentSceneNavKey: null,
                menuItems: MY_JOURNEY_JSON.menu,
                linkHotspots: preparedLinks,
                debugEnabled: false,
                sceneNavKeys: sceneNavKeys,
                controls: {
                    isVisible: false,
                    isToggled: false
                },
                hotspots: preparedHotspots,
                languageEnabled: true,
                hotspotContentIsToggled: false,
                hasVRDisplay: false,
                CBDisplayToggled: false,
                progressPercent: 0,
                canShowEnd: true,
                notifications: NOTIFICATIONS_JSON.notifications,
                mainLogo: CONFIG.MAIN_LOGO,
                mainTitle: CONFIG.MAIN_TITLE,
                loginButonClassName: CONFIG.LOGIN_BUTTON_CLASSNAME,
                loginSubButtonClassName: CONFIG.LOGIN_SUBBUTTON_CLASSNAME,
                aboutButtonClassName: CONFIG.ABOUT_BUTTON_CLASSNAME,
                modules: [],
                activeModule: 'panoramas'
            };
        }
    }
</script>

<style lang="less">
    @import "./less/app.less";

    @import "./less/pano.less";
</style>
