<template>
    <div :class="componentClassName" :style="contentModalStyle">
        <div class="content-modal__header" v-if="options.hasHeader && !options.videoId">
            <button class="btn-transparent pull-right close-btn" @click="close()"><i class="icon-close-alt"></i></button>
            <span class="already-checked pull-right" v-if="options.hasCheckedSign">
                <span>{{ $t('already-checked') }}</span>
                <span class="checked-icon-container">
                    <i class="icon-check"></i>
                </span>
            </span>
            <button  class="btn-transparent text-primary back-btn" @click="close()"><i class="icon-arrow-right"></i></button>
            <div class="clearfix"></div>
        </div>

        <div class="content-modal__content" :class="contentClassName">
            <div v-if="!options.videoId || options.showText" class="content-text">
                <span class="content-modal__form" v-html="formHTML" v-if="options.form"></span>
                <span class="content-modal__content-image" v-if="options.image"><img :src="options.image" :alt="options.title" /></span>
                <div class="content-modal__text-wrap">
                    <h3 v-if="options.title">{{options.title}}</h3>
                    <div v-html="options.content"></div>
                </div>
            </div>
            <div v-if="options.videoId" class="player-outer-border" :class="playerLoadedClassName">
                <div class="player-wrap" :style="{ transform: this.rotated ? 'rotate(270deg)' : ''}">
                  <div ref="playerWrap">
                    <div id="contentModalYTPlayer"></div>
                  </div>
                  <div style="width: 100%; padding: 10px; display: flex; flex-direction: row;" v-bind:style="{justifyContent: this.width <= 480 ? 'space-between' : 'end'}">
                    <button v-if="this.width <= 480" @click="rotateScreen()" style="color: white; font-size: 9px; display: flex; flex-direction: row; align-items: center;">
                      <span style="margin-left: 10px; background: #C4D600; width: 30px; height: 30px; border-radius: 50%; display: flex; flex-direction: column; justify-content: center; align-items: center">
                        <img style="width: 18px; height: 18px;" src="img/fullscreen.png"/>
                      </span>
                    </button>
                    <button @click="close()" style="color: white; font-size: 9px; display: flex; flex-direction: row; align-items: center;">
                      Skip
                      <span style="margin-left: 10px; background: #C4D600; width: 30px; height: 30px; border-radius: 50%; display: flex; flex-direction: column; justify-content: center; align-items: center">
                        <img style="width: 18px; height: 18px;" src="img/r.png"/>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="player-cover" @click="playVideo()" v-if="!isPlaying"><span class="player-circle"><i class="icon-play"></i></span></div>
            </div>
        </div>

        <div class="content-modal__footer" v-if="options.hasFooter">
            <div class="pull-right save-to-my-notes" @click="toggleSave()" v-if="options.saveToNote">
                <span class="note-save switcher-wrap" :class="noteSaveClassName"><span class="switcher"></span></span> {{$t('saveToMyNotes')}}
            </div>
            <div class="btn-wrap" v-if="!options.hideBottomButton">
                <button class="btn btn-primary" @click="triggerButton()">{{$t(options.buttonTitle)}}</button>
            </div>
            <div class="clearfix"></div>
            <div class="content-modal__must-read-banner" v-if="options.isMustRead">
                <div class="pull-right set-read-checkbox" @click="setRead()"><span class="checkbox-square icomoon-font" :class="checkedClassName"></span> {{$t('iveReadThis')}}</div>
                <i class="icon-warning"></i> {{$t('mustReadModal')}}
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Helpers from '@/helpers.js'
    import CONFIG from '@/config.js'

    export default {
        name: 'ContentModal',
        props: {
            options: {
                'type': Object,
                'default': function () {
                    return CONFIG.CONTENT_MODAL_DEFAULTS
                }
            }
        },
        computed: {
            componentClassName: function () {
                let componentCSSClass = 'content-modal';

                if (this.isToggled) {
                    componentCSSClass += ' toggled';
                }

                if (this.isVisible) {
                    componentCSSClass += ' visible';
                }

                if (this.options.addClass) {
                    componentCSSClass += ' ' + this.options.addClass;
                }

                componentCSSClass += ' content-modal-type-' + this.options.type;

                if (this.options.isMustRead) {
                    componentCSSClass += ' is-must-read';
                }

                if (this.options.useAltVideoPlayer) {
                    componentCSSClass += ' is-alt-video-player';
                }

                return componentCSSClass;
            },
            noteSaveClassName: function () {
                let noteSaveClassName = '';

                if (this.options.isSaved) {
                    noteSaveClassName = 'active';
                }

                return noteSaveClassName;
            },
            playerLoadedClassName: function () {
                let loadedClassName = '';

                if (this.playerReady) {
                    loadedClassName += ' player-loaded';
                }


                return loadedClassName;
            },
            contentClassName: function () {
                let contentDClassName = '';

                if (this.isPlaying) {
                    contentDClassName += ' player-playing';
                }

                return contentDClassName;
            },
            formHTML: function () {
                return '';
            },
            checkedClassName: function () {
                return this.options.wasRead ? 'is-checked' : '';
            }
        },
        data: function () {
            return {
                isVisible: false,
                isToggled: false,
                isChecked: false,
                isPlaying: false,
                playerReady: false,
                rotated: false,
                player: null,
                contentModalStyle: {},
                width: window.innerWidth
            };
        },
        methods: {
            onPlayerStateChange: function (event) {
                if (event.data == YT.PlayerState.PAUSED) {
                    this.isPlaying = false;
                }

                if (event.data == YT.PlayerState.PLAYING) {
                    this.isPlaying = true;
                }
            },
            toggle: function () {
                Helpers.UI.dynamicToggle(this);
            },
            close: function () {
                if (this.player && this.player.stopVideo) {
                    this.player.stopVideo();
                    this.isPlaying = false;
                }

                this.playerReady = false;

                Helpers.UI.dynamicToggle(this, null, true);
                this.$emit('wasClosed');
            },
            rotateScreen: function () {
              if(window.innerWidth > 480) return;

              this.rotated = !this.rotated;
              this.updateYTPlayer();
            },
            toggleSave: function () {
                this.options.isSaved = !this.options.isSaved;
            },
            setRead: function () {
                if (!this.options.wasRead) {
                    this.options.wasRead = true;

                    this.$emit('wasRead', this.options.modalName);
                }
            },
            playVideo: function () {
                if (!this.player) {
                    return;
                }

                this.player.playVideo();
                this.isPlaying = true;
            },
            updateYTPlayer: function () {
                let self = this;

                if (!self.options.videoId) {
                    return;
                }

                self.$refs.playerWrap.innerHTML = '<div id="contentModalYTPlayer"></div>';

                if (!self.options.useAltVideoPlayer) {
                    self.player = new YT.Player('contentModalYTPlayer', {
                        height: '480',
                        width: '670',
                        videoId: self.options.videoId,
                        playerVars: {
                            'controls': 0,
                            'modestbranding': 0,
                            'enablejsapi': 1,
                            'iv_load_policy': 3,
                            'rel': 0
                        },
                        events: {
                            'onReady': self.onPlayerReady,
                            'onStateChange': self.onPlayerStateChange
                        }
                    });
                } else {
                    self.player = this.createAltPlayer({
                        width: 670,
                        height: 380,
                        videoPath: self.options.videoId,
                        videoType: self.options.videoType,
                        appendTo: 'contentModalYTPlayer',
                        onReady: self.onPlayerReady,
                        onStateChange: self.onAltPlayerStateChange,
                        poster: self.options.videoPoster,

                    });
                }
            },
            createAltPlayer: function (opt) {
                opt = opt || {};

                if (!opt.videoPath) {
                    return false;
                }

                var self = this;

                opt.videoType = opt.videoType || 'video/mp4';
                opt.width = opt.width || 1280;
                opt.height = opt.height || 720;
                opt.autoplay = opt.autoplay || false;

                const windowWidth = window.innerWidth;

                if(windowWidth < 480) {
                  if(this.rotated) {
                    opt.height = 270;
                    opt.width = 800;
                  } else {
                    opt.height = 250;
                    opt.width = 380;
                  }
                }

                let videoPlayer = document.createElement('video');
                videoPlayer.setAttribute('width', opt.width);
                videoPlayer.setAttribute('height', opt.height);
                videoPlayer.setAttribute('autoplay', opt.autoplay);
                videoPlayer.setAttribute('poster', opt.poster);
                videoPlayer.setAttribute('playsinline', true);
                videoPlayer.setAttribute('webkit-playsinline', true);

                videoPlayer.sourceOptions = opt;

                let videoPlayerSource = document.createElement('source');
                videoPlayerSource.setAttribute('src', opt.videoPath);
                videoPlayerSource.setAttribute('type', opt.videoType);

                videoPlayer.playVideo = function() {
                    videoPlayer.play();
                };

                videoPlayer.stopVideo = function() {
                    videoPlayer.pause();
                };

                videoPlayer.oncanplay = function() {
                    self.playerReady = true;
                };

                videoPlayer.onclick = function() {
                    if(!videoPlayer.paused) {
                        videoPlayer.pause();
                    } else {
                        videoPlayer.play();
                    }
                };

                videoPlayer.onplay = function() {
                    self.isPlaying = true;
                };

                videoPlayer.onpause = function() {
                    self.isPlaying = false;
                };

                videoPlayer.appendChild(videoPlayerSource);

                if(opt.appendTo) {
                    document.getElementById(opt.appendTo).appendChild(videoPlayer);
                }

                return videoPlayer;
            },
            onPlayerReady: function () {
                this.playerReady = true;
            },
            removeYTPlayer: function () {
                this.playerReady = false;
                this.player = null;
            },
            triggerButton: function () {
                this.$emit('modalButtonTriggered', this.options.sourceContent);
            },
            clearModalStyle: function () {
                this.contentModalStyle = {};
            },
            setModalStyle: function (contentModalStyle) {
                this.contentModalStyle = contentModalStyle;
            }
        }
    }
</script>

<style scoped lang="less">
    @import '../less/colors.less';

    .content-modal {
        position: absolute;
        background: #fff;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 13;
        box-sizing: border-box;
        padding: 52px 20px 101px 20px;
        display: none;

        @media only screen and (max-width: 480px),(max-height: 480px) {
            top: 50%;
            //transform: translate(-50%, -100%);
        }

        &.toggled {
            display: block;
            opacity: 0;
            width: 64px;
            max-height: 64px;
            overflow: hidden;
            border-radius: 32px;
            transition: @transitionsLength all;
        }

        &.visible {
            width: 670px;
            max-height: 100vh;
            overflow: visible;
            border-radius: 10px;
            opacity: 1;
            .content-modal__content, .content-modal__header, .content-modal__footer {
                opacity: 1;
                transition: @fastTransitionsLength all;
                transition-delay: @transitionsLength;
            }

            @media only screen and (max-width: 480px),(max-height: 480px) {
                width: 90vw;
                height: 100%;
                max-height: 71vh;

                &.is-alt-video-player {
                    width: 100vw;
                    height: 100vh;
                    max-height: 100vh;
                    top: 50%;
                    bottom: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 200;
                    border-radius: 0;
                }
            }

            @media only screen and (max-width: 480px) {
                &.is-alt-video-player {
                    .player-wrap {
                        /*transform: rotate(-90deg) translate(0%, -60vw);
                        position: absolute;
                        bottom: 0;
                        height: 100%;
                        left: 50%;*/
                        /*position: relative;
                        bottom: 0px;
                        left: 0px;
                        height: 100vw;
                        width: 100vh;
                        transform: rotate(-90deg);*/
                        position: absolute;
                        bottom: 0;
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    .player-circle {
                        //transform: rotate(-90deg) translate(-50%, -50%);
                    }

                    .content-modal__header {
                        position: relative;
                        top: 48px;
                    }
                }
            }

            @media only screen and (max-height: 480px) and (orientation: landscape) {
                max-width: 100vw;
                height: 100vh;
            }
        }

        .content-modal__header {
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            line-height: 52px;
            position: absolute;
            padding: 0 20px;
            z-index: 5;

            .already-checked {
                margin-right: 20px;
                align-items: center;
                display: flex;
                .checked-icon-container {
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    border-radius: 50%;
                    background: @checkedHotspotColor;
                    text-align: center;
                    margin-left: 8px;
                    opacity: 1;
                    position: relative;
                    i {
                        border-radius: 50%;
                        font-size: 20px;
                        color: @btn-default-bg;
                        transition: all @transitionsLength;
                        opacity: 1;
                        position: absolute;
                        top: 2px;
                        left: 2px;
                    }
                }
                span {
                    color: @text;
                    opacity: .2;
                }
            }
        }

        .content-modal__content {
            line-height: 21px;
            color: @inputFieldLabel;
            font-size: 14px;
            opacity: 0;

            @media only screen and (max-width: 480px),(max-height: 480px) {
                margin-right: -20px;
                padding-right: 20px;
                max-height: 100%;
                overflow: auto;
            }

            @media only screen and (max-height: 480px) and (orientation: landscape) {
                max-height: 100vh;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            a {
                color: @primary !important;
            }

            .content-text {
                padding-top: 12px;
            }

            h3 {
                font-size: 16px;
                margin-bottom: 16px;
                line-height: 21px;
                font-weight: 700;

                &.bigger {
                    font-size: 22px;
                    line-height: 27px;
                }

            }

            h2 {
                font-size: 28px;
                margin-bottom: 16px;
                line-height: 34px;
                font-weight: 700;

                .subtext {
                    line-height: 18px;
                    font-size: 12px;
                    color: @textLighter;
                }

            }

            .content-modal__content-image {
                float: right;
                margin: 0 13px 0 40px;
                max-width: 260px;

                img {
                    width: 100%;
                    border-radius: 10px;
                    margin-bottom: 15px;
                }

            }
        }

        .content-modal__footer {
            opacity: 0;
            font-size: 14px;
            font-weight: 500;
            padding: 20px;
            border-top: 1px solid @borderColor;
            bottom: 0;
            left: 0;
            width: 100%;
            position: absolute;
            z-index: 5;

            .save-to-my-notes {
                color: @placeholderColor;
                text-transform: uppercase;
                line-height: 40px;
                cursor: pointer;

                .switcher-wrap {
                    margin-right: 8px;
                }
            }
        }
    }

    .content-modal-type-youtube {
        background: #333;
        padding: 0;
        overflow: hidden;
        .content-modal__header {
            .back-btn {
                i {
                    color: #fff;
                }

            }

            .close-btn {
                color: #fff;
                opacity: 0.6;
            }

            .already-checked {
                span {
                    color: @placeholderColor;
                    opacity: 1;
                }
            }
        }

        .content-text {
            position: absolute;
            top: 64px;
            color: #fff;
            padding: 20px 128px 0 20px;
            z-index: 5;
            opacity: 1;
            transition: @transitionsLength all;

            h3 {
                font-size: 28px;
                color: #fff;
            }
        }

        .player-circle {
            top: 60%;
        }

        .player-playing {
            .content-text {
                opacity: 0;
            }
        }

        &.is-alt-video-player {
            .player-circle {
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .content-modal-type-htsp-large-text {
        &.visible {
            width: 520px;
            min-height: 290px;
        }

        .content-text {
            h3 {
                margin-top: 6px;
                font-size: 28px;
                line-height: 34px;
            }
        }
    }

    .content-modal-type-htsp-with-image {
        &.visible {
            width: 611px;
        }

        .content-text {
            padding-top: 15px;
            min-height: 185px;

            &:after {
                content: "";
                display: block;
                clear: both;
                width: 0;
                height: 0;
            }
        }
    }

    .content-modal-type-alert {
        &.visible {
            width: 270px;
        }

        .content-modal__footer {
            .btn-wrap {
                width: 100%;

                .btn {
                    width: 100%;
                }
            }
        }
    }

    .content-modal-type-htsp-coloured-fill {
        background: @primary;
        color: #fff;
        &.visible {
            width: 520px;
            height: 283px;
        }

        .content-text, .back-btn {
            color: #fff;
        }

        .btn-primary {
            background: #fff;
            color: @primary;
        }

        .save-to-my-notes {
            color: #fff !important;
        }
    }

    .content-modal__form {
        display: block;
        float: right;
        width: 280px;
        margin-left: 40px;
    }

    .content-modal__must-read-banner {
        background: @primary;
        color: #fff;
        margin: -20px;
        margin-top: 20px;
        margin-bottom: -40px;
        line-height: 65px;
        height: 65px;
        font-size: 14px;
        padding: 0 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        i {
            font-size: 19px;
            vertical-align: middle;
            position: relative;
            top: -2px;
            margin-right: 5px;
        }

        .set-read-checkbox {
            cursor: pointer;

            .checkbox-square {
                display: inline-block;
                margin-right: 5px;
                vertical-align: middle;
                position: relative;
                top: -2px;
            }
        }
    }

    .is-must-read {

        padding: 52px 20px 151px 20px;

        .content-modal__footer {
            padding-bottom: 0;
        }
    }

    .player-outer-border, .player-cover {
        border-radius: 12px;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .player-wrap {
        margin: 0;
        padding: 0;
    }

    @media only screen and (max-height: 480px) and (orientation: landscape) {
        .player-outer-border, .player-cover {
            border-radius: 0;
        }

        .is-alt-video-player {
            .content-modal__content {
                margin-right: 0;
                padding-right: 0;
                width: 100vw;
                height: 100vh;
                position: relative;
            }

            .content-modal__header {
                padding-right: 64px;
                padding-left: 64px;
            }

            .player-outer-border {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100vw;
                object-fit: cover;
                transform: translate(-50%, -50%);
            }

        }

    }

</style>
