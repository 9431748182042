<template>
    <div class="box-menu__holder" :class="mobileMenuClassName">
        <div class="box-menu__mobile">
            <button class="btn-transparent box-menu__mobile-invoke" @click="toggleMobileMenu()"><i class="icon-burger"></i></button>
            <button class="btn-transparent box-menu__mobile-close" @click="toggleMobileMenu()"><i class="icon-close"></i></button>
        </div>

        <div class="box-menu">
            <div class="box-menu__header">
                <h2 class="box-menu__header-title">{{ $t('menu.my-journey')}}</h2>
                <div class="box-menu__current-section" v-if="currentMenuItem">
                    <div class="box-menu__current-section-icon">&nbsp;<i :class="currentMenuItem.icon"></i></div>
                    <div class="box-menu__current-section-title">{{getText('title', currentMenuItem)}}</div>
                    <span class="title-badge" :class="getItemBadgeClassName(currentMenuItem)">{{getItemBadgeContent(currentMenuItem)}}</span>
                </div>
                <button class="btn-transparent collapse-btn" @click="collapse" v-if="expanded"><i class="icon-chevron-up"></i></button>
                <button class="btn-transparent expand-btn" @click="expand" v-else><i class="icon-chevron-down"></i></button>
            </div>

            <div class="box-menu__wrap">
                <div class="box-menu__inner-wrap">
                    <ul class="box-menu__parent">
                        <li v-for="(menuItem, index) in items" class="box-menu__parent-item" :class="activeClassName(menuItem)" v-bind:key="index" :id="getItemId(menuItem)">
                            <a :href="getText('link', menuItem)" @click="openItem($event, menuItem)">
                                <strong v-if="menuNumbers" :class="{ 'is-active': isActive(menuItem), 'was-passed': wasPassed(menuItem),  'is-unlocked': isUnlocked(menuItem)}">
                                    &nbsp;<i :class="menuItem.icon"></i>
                                </strong>
                                <span class="title-text">{{getText('title', menuItem)}}</span>
                                <span class="title-badge" :class="getItemBadgeClassName(menuItem)">{{getItemBadgeContent(menuItem)}}</span>
                            </a>
                            <div class="content-text" v-if="getText('content', menuItem, true) !== ''" v-html="getText('content', menuItem, true)"></div>
                            <ul class="box-menu__child" v-if="isActive(menuItem)" :style="{ marginTop: getText('content', menuItem, true) !== '' ? '20px' : '' }">
                              <li v-for="(menuSubItem, subIndex) in menuItem.children"  v-bind:key="subIndex">
                                <a :href="getText('link', menuSubItem)" :class="{ 'was-subpassed': wasSubPassed(menuSubItem), 'is-subactive': isSubActive(menuSubItem)}" @click="openItem($event, menuSubItem, true)">{{getText('title', menuSubItem)}} <i class="icon-next"></i></a>
                              </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CONFIG from '../config.js'
    import Helpers from '../helpers.js'

    export default {
        name: 'boxMenu',
        props: [
            'items',
            'progress',
            'checklist',
            'hotspotGroups'
        ],
        computed: {
            mobileMenuClassName: function () {
                var mobileMenuClassName = [];

                if (this.mobileMenu.isVisible) {
                    mobileMenuClassName.push('mobile-visible');
                }

                if (this.mobileMenu.isToggled) {
                    mobileMenuClassName.push('mobile-toggled');
                }

                return mobileMenuClassName.join(' ');
            }
        },
        mounted() {
            this.wrap = document.querySelector('.box-menu__wrap');
            this.wrapInner = document.querySelector('.box-menu__inner-wrap');
        },
        methods: {
            getHotspotsCounterData: function (menuItem) {
                let data = {
                    finished: 0,
                    tofinish: 0
                };

                let doneHotspots = this.progress.hotspots || {};
                let hotspotGroupItems = this.hotspotGroups[menuItem.hotspotsMenuGroup];
                Object.keys(hotspotGroupItems).map((hs) => {
                    data.tofinish++;
                    if (doneHotspots[hs]) {
                        data.finished++;
                    }
                });
                
                return data;
            },
            getItemId: function(menuItem) {
                return 'group_nav_menu_item_' + menuItem.hotspotsMenuGroup;
            },
            getItemBadgeClassName: function (menuItem) {
                var hotspotsData = this.getHotspotsCounterData(menuItem);
                if (hotspotsData.tofinish > 0 && hotspotsData.finished === hotspotsData.tofinish) {
                    return 'badge-completed';
                }
                return '';
            },
            getItemBadgeContent: function (menuItem) {
                var hotspotsData = this.getHotspotsCounterData(menuItem);
                
                return hotspotsData.finished + '/' + hotspotsData.tofinish;
            },
            croppedText(text) {
                return Helpers.UI.cropText(text, this.maxLength);
            },
            setOtherInfoDisabled: function (otherInfoDisabled) {
                this.otherInfoDisabled = otherInfoDisabled;
            },
            toggleMobileMenu: function () {
                Helpers.UI.dynamicToggle(this.mobileMenu);
            },
            activeClassName: function (item, subitem) {
                var locale = this.$root.$i18n.locale;
                subitem = subitem || false;
                var activeClassName = '';
                var activeItem = !subitem ? this.activeMenuItem : this.activeMenuSubItem;
                //if (item.link[locale] === activeItem) {
                if (item.id === activeItem) {
                    activeClassName = 'active';
                    if(item.hotspotsMenuGroup) {
                        var itemDom = document.getElementById('group_nav_menu_item_' + item.hotspotsMenuGroup);
                        if(this.wrap) {
                            this.wrap.scrollTop = itemDom.offsetTop;                        
                        }
                    }
                }
                
                return activeClassName;
            },
            isChecked: function (item) {
                //TEMP Selection
                if (item === 'reception') {
                    return '';
                }

                return 'nope';
            },
            wasPassed: function (item) {
                return this.$parent.passedScenes[item.id];
            },
            isActive: function (item) {
              if(this.activeMenuSubItem !== null) {
                //Workaround because we only have one item with children
                return true;
              }
              return item.sceneId == this.$parent.currentSceneId;
            },
            wasSubPassed: function (item) {
                return this.$parent.displayedScenes[item.sceneKey];
            },
            isSubActive: function (item) {
              if(this.$parent.activeMenuItem === null && item.sceneKey === 0) return true

              if(item.sceneNavKey === this.$parent.currentSceneNavKey) {
                return true;
              }

              return false;
            },
            isUnlocked: function (item) {
              if(this.wasPassed(item) || this.isActive(item)) return false;

              let hasDonePrevious = true;
              if (item.sceneKey >= 0 && item.checklistGroup > 1) {
                hasDonePrevious = Helpers.hasDoneChecklistGroup(item.checklistGroup - 1, this.checklist, this.progress);
              }

              return hasDonePrevious;
            },
            hasChildren: function (item) {
                item.children = item.children || [];
                let filteredChildren = [];
                item.children.map(function (subitem) {
                    if (!subitem.hide) {
                        filteredChildren.push(subitem);
                    }
                });

                return filteredChildren.length > 0;
            },
            openItem: function ($event, menuItem, subitem) {
                this.$emit('beforeMenuItemOpen');
                console.log(menuItem.children);
                let hasDonePrevious = true;
                if (menuItem.sceneKey >= 0 && menuItem.checklistGroup > 1) {
                    hasDonePrevious = Helpers.hasDoneChecklistGroup(menuItem.checklistGroup - 1, this.checklist, this.progress);
                }

                this.showSubmenu = !menuItem.singleMenuItem;

                let locale = this.$root.$i18n.locale;
                subitem = subitem || false;
                if ($event) {
                    $event.preventDefault();
                }

                //Previous checklist group not done
                //Don't open menu item
                if (!hasDonePrevious) {
                    return;
                }

                let keepSubitems = menuItem.keepSubitems || false;

                if (!keepSubitems && this.hasChildren(menuItem)) {
                    let filteredChildren = [];
                    menuItem.children.map(function (subitem) {
                        if (!subitem.hide) {
                            filteredChildren.push(subitem);
                        }
                    });

                    //Sort menu items
                    filteredChildren.sort(function (a, b) {
                        return (a.order > b.order) ? 1 : -1;
                    });

                    this.subitems = filteredChildren || false;
                }
                
                this.currentMenuItem = menuItem;

                if (!subitem) {
                    //this.activeMenuItem = menuItem.link[locale];
                    this.activeMenuItem = menuItem.id;
                    this.activeMenuSubItem = null;
                    
                    if (!this.subitems) {
                        this.toggleMobileMenu();
                    }
                } else {
                    //this.activeMenuSubItem = menuItem.link[locale];
                    this.activeMenuSubItem = menuItem.sceneKey;
                    this.activeMenuItem = null;
                    this.toggleMobileMenu();
                }

                this.$emit('menuItemOpened', menuItem);
            },
            back: function ($event) {
                $event.preventDefault();

                this.subitems = false;
            },
            getText: function (textKey, textData, cropped) {
                cropped = cropped || false;
                textData = textData || {};
                const locale = this.$root.$i18n.locale;
                let resultText = textKey;

                if (!textData[textKey]) {
                    return resultText;
                }

                if (!textData[textKey][locale]) {
                    const localeDefault = CONFIG.DEFAULT_LOCALE; //add fallback key to locale
                    resultText = textData[textKey][localeDefault];
                } else {
                    resultText = textData[textKey][locale];
                }
                
                if(!cropped) {
                    return resultText;
                }

                if(resultText === undefined) return "";

                return Helpers.UI.cropText(resultText, this.maxLength);
            },
            openOtherTarget: function () {
                this.$emit('openOtherTarget', this);
            },
            collapse() {
                this.expanded = false;
                this.$emit('boxMenuCollapsed');
            },
            expand() {
                this.expanded = true;
                this.$emit('boxMenuExpanded');
            },
            readMore($event, item) {
                this.openItem($event, item);
                this.$emit('readMore', item);
            }
        },
        data() {
            return {
                wrap: null,
                wrapInner: null,
                activeMenuItem: null,
                activeMenuSubItem: null,
                currentMenuItem: null,
                otherInfoDisabled: false,
                maxLength: 50,
                expanded: true,
                subitems: false,
                prefixT: CONFIG.MENU_TRANSLATION_PREFIX,
                backLink: '',
                pageTitle: '',
                menuNumbers: CONFIG.MENU_USE_NUMBERS,
                logoPath: CONFIG.MAIN_LOGO,
                locale: this.$root.$i18n.locale,
                showSubmenu: true,
                mobileMenu: {
                    isVisible: false,
                    isToggled: false
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/common.less";
    @import "../less/elements.less";

    .box-menu__holder {
        position: absolute;
        top: 20px;
        right: 19px;
        z-index: 13;
        transition: all @transitionsLength;

        /*@media only screen and (max-width: 480px),(max-height: 480px) {
            top: 0;
            left: 0;
            width: 100vw;
            z-index: 100;
            display: block;   
            background: rgba(255,255,255,0);
            transition: all @transitionsLength;

            &.mobile-toggled {
                height: 100vh;
            }

            &.mobile-visible {
                background: rgba(255,255,255,1);
            }
        }*/
    }

    .box-menu__mobile {
        display: none;
        text-align: right;

        .btn-transparent {
            color: #fff;
            font-size: 200%;
            font-weight: bold;
        }

        /*@media only screen and (max-width: 480px),(max-height: 480px) {
            display: block;
            line-height: 64px;
            padding-right: 21px;
            background: rgba(250,250,250,0);
            border-bottom: 1px solid rgba(221,221,221,0);
            transition: all @transitionsLength;

            .box-menu__mobile-close {
                display: none;
            }

            .box-menu__mobile-invoke {
                display: inline-block;
            }

            .mobile-visible & {
                border-bottom-color: rgba(221,221,221,1);
                background: rgba(250,250,250,1);

                .btn-transparent {
                    color: @text;
                }

                .box-menu__mobile-close {
                    display: inline-block;
                }

                .box-menu__mobile-invoke {
                    display: none;
                }
            }
        }*/
    }

    .box-menu-other-info {
        .box-default();
        box-shadow: none;
        width: 400px;
        background: @menu-other-background;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        @media only screen and (max-width: 480px),(max-height: 480px) {
            width: auto;
            display: block;
            opacity: 1;
            transition: all @transitionsLength;
            border-bottom: 0;
            border-radius: 20px;
            height: 40px;
            line-height: 40px;
            position: absolute;
            top: 20px;
            left: 20px;

            .mobile-toggled & {
                opacity: 0;
            }

            .mobile-visible & {
                display: none;
            }
        }

        button {
            text-decoration: none;
            display: block;
            line-height: 66px;                        
            height: 66px;
            color: @primary-invert;
            padding: 0 22px;
            font-size: 14px;
            position: relative;
            .transition(all);
            width: 100%;
            text-align: center;
            text-transform: uppercase;

            i {
                .vertically-centered-text(25px);
                left: auto;
                right: 10px;
                width: 32px;
            }

            .icon-plus {
                display: none;
            }
        }

        @media only screen and (max-width: 480px),(max-height: 480px) {
            button { 
                height: 40px;
                line-height: 40px;
                padding-right: 52px;

                .icon-next {
                    display: none;
                }

                .icon-plus {
                    display: block;
                    font-size: 100%;
                    width: 24px;
                }
            }
        }

        &:hover {
            background: darken(@menu-other-background, 2%);
            border-color: darken(@menu-other-border-bottom, 5%);
            .transition(all);
        }
    }
    
    .box-menu__current-section {
        display: none;
        position: relative;
        font-size: 19px;
        height: 20px;
        .transition(all);
        opacity: 0;
        
        .box-menu__current-section-icon {
            width: 52px;
            height: 52px;
            position: absolute;
            left: -18px;
            top: -18px;
            background: @secondary;
            color: #fff;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            line-height: 52px;
            text-align: center;
            font-size: 24px;
            padding-right: 6px;
        }
        
        .box-menu__current-section-title {
            padding-left: 52px;
            font-size: 15px;
            line-height: 21px;
            position: relative;
            top: -4px;
        }
        
        @media only screen and (max-width: 480px),(max-height: 480px) {
            display: block;
            width: 100%;
            opacity: 1;
            
            .with-box-menu-expanded & {
                display: none;
            }
            
            .title-badge {
                top: -4px !important;                
                right: 27px !important;                   
                color: @primary !important;
                border-color: @primary !important;
                
                &.badge-completed {
                    color: #fff !important;
                }
            }
        }        
    }
    
    @media only screen and (max-width: 480px) {
        .box-menu__header-title {
            display: none;
            
            .with-box-menu-expanded & {
                display: block;
                font-size: 19px;
            }
        }
    }

    .box-menu {
        .box-default();
        width: 370px;
        min-height: 80px;
        
        @media only screen and (max-width: 480px),(max-height: 480px) {
            width: calc(100vw - 40px);
            min-height: auto;
        }
        
        .title-badge {
                        display: block;
                        position: absolute;
                        right: 24px;
                        top: 24px;
                        border-radius: 12px;
                        border: 1px solid @disabledHotspotCounter;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 22px;
                        height: 24px;
                        text-align: center;
                        width: 60px;
                        color: @disabledHotspotCounter;
                        transition: .5s background;
                        
                        @media only screen and (max-width: 480px),(max-height: 480px) {
                            top: 8px;
                        }
                        
                        &.badge-completed {
                            background: @primary;
                            border-color: @primary;
                            color: #fff;
                        }
                    }

        /*@media only screen and (max-width: 480px),(max-height: 480px) {
            width: 100%;
            box-shadow: none;
            margin-bottom: 0;
            display: none;
            opacity: 0;
            transition: all @transitionsLength;

            .mobile-toggled & {
                display: block;
            }

            .mobile-visible & {
                opacity: 1;
            }
        }*/

        .was-subpassed {
          color: @menu-item-passed-background;
        }

        .is-subactive {
          color: @menu-item-active-background;
        }

        ul {
            margin-top: 5px;
            @media only screen and (max-width: 480px),(max-height: 480px) {
                width: 100%;
            }

            li {
                @media only screen and (max-width: 480px),(max-height: 480px) {
                    width: 100%;
                }

                a {
                    text-decoration: none;
                    display: block;
                    line-height: 72px;                        
                    height: 72px;
                    color: @text;
                    padding: 0 22px;
                    font-size: 17px;
                    position: relative;
                    .transition(all);
                    font-weight: 700;
                    
                    @media only screen and (max-width: 480px),(max-height: 480px) {
                        height: 36px;
                        line-height: 36px;
                        font-size: 14px;
                        width: 100%;
                    }

                    strong {
                        display: inline-block;
                        font-family: 'Montserrat', sans-serif;     
                        font-weight: bold;
                        font-size: 17px;
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        border-radius: 24px;
                        text-align: center;
                        position: relative;
                        background: @activityDotBackground;
                        color: #fff;
                        
                        @media only screen and (max-width: 480px),(max-height: 480px) {
                            width: 36px;
                            height: 36px;
                            line-height: 34px;
                        }

                        i {
                            color: #fff;
                            
                            @media only screen and (max-width: 480px),(max-height: 480px) {
                                font-size: 19px;
                            }
                        }

                        /*&:after {
                          content: "";
                          display: block;
                          width: 2px;
                          background: @activityDotBackground;
                          height: 72px;
                          position: absolute;
                          left: 23px;
                        }*/

                        &:before {
                            content: "";
                            display: block;
                            width: 2px;
                            background: @activityDotBackground;
                            height: 72px;
                            position: absolute;
                            bottom: 48px;
                            left: 23px;
                            
                            @media only screen and (max-width: 480px),(max-height: 480px) {
                                height: 27px;
                                width: 1px;
                                left: 17px;
                                bottom: 36px;
                            }
                        }

                        &.was-passed {
                            background: @menu-item-passed-background;

                            &:before {
                                background: @menu-item-passed-background;
                            }
                        }

                        &.is-active {
                          background: @menu-item-active-background;
                        }

                        &.is-unlocked {
                          box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
                          animation: pulse-white 2s infinite;
                        }

                        @keyframes pulse-white {
                          0% {
                            transform: scale(0.95);
                            box-shadow: 0 0 0 0 rgba(0, 163, 224, 0.7);
                          }

                          70% {
                            transform: scale(1);
                            box-shadow: 0 0 0 10px rgba(0, 163, 224, 0);
                          }

                          100% {
                            transform: scale(0.95);
                            box-shadow: 0 0 0 0 rgba(0, 163, 224, 0);
                          }
                        }

                    }

                    .title-text {
                        display: inline-block;
                        line-height: 20px;
                        vertical-align: middle;
                        padding-left: 14px;
                        color: @text;

                        .subtext {
                            display: block;
                            color: #bbb;
                            font-size: 66%;
                        }
                    }

                    i {
                        .vertically-centered-text(25px);
                        left: auto;
                        right: 8px;
                        width: 32px;
                        color: #fff;
                        
                        @media only screen and (max-width: 480px),(max-height: 480px) {
                            width: 21px;
                            right: 7px;                            
                        }

                        &.icon-checked {
                            color: #fff;
                            right: 52px;
                            font-size: 24px;
                            background: #29d55a;
                            width: 21px;
                            height: 21px;
                            border-radius: 11px;

                            &:before {
                                position: relative;
                                top: -5px;
                                left: -1px;
                            }
                        }
                    }

                    &:hover {
                        color: darken(@text, 5%);
                        .transition(all);
                    }
                }

                &.active {
                    /*border-right: 6px solid @menu-active-border;*/

                    a {
                        /*color: @menu-active-border;*/

                        i {
                            color: #fff;
                            right: 8px;

                            &.icon-checked {
                                width: 21px;
                                height: 21px;
                                right: 52px;
                                color: #fff;
                            }
                        }
                    }

                    .title-badge {
                        color: @primary;
                        border-color: @primary;
                        
                        &.badge-completed {
                            color: #fff;
                        }
                    }
                }

                &:first-child {
                    strong {
                        &:before {
                            display: none;
                        }
                    }
                }
                .content-text {
                    position: absolute;
                    right: 30px;
                    width: 253px;
                    height: 42px;
                    top: 51px;
                    overflow: hidden;
                    font-size: 14px;
                    line-height: 21px;
                    color: @inputFieldLabel;
                    
                    @media only screen and (max-width: 480px),(max-height: 480px) {
                        display: none;
                    }
                }
                .read-more {
                    margin-top: 30px;
                    margin-right: 14px;
                    display: inline-block;
                    float: right;
                    font-size: 14px;
                    line-height: 17px;
                    cursor: pointer;
                    color: @primary;
                    font-weight: 500;
                }
            }
        }
    }

    .box-menu__header {
        padding: 30px 21px;
        font-size: 24px;
        font-weight: 700;
        position: relative;
        
        @media only screen and (max-width: 480px),(max-height: 480px) {
            padding: 18px;
            height: 52px;
        }

        .expand-btn, .collapse-btn {
            position: absolute;
            font-size: 18px;
            right: 30px;
            top: 26px;
            color: @placeholderColor;
            
            @media only screen and (max-width: 480px),(max-height: 480px) {
                right: 20px;
                top: 18px;
                font-size: 14px;
            }
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .box-menu__wrap {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transition: all @transitionsLength;

        .box-menu__inner-wrap {
            width: 720px;
            .transition(all);
            position: relative;
            left: 0;

            @media only screen and (max-width: 480px),(max-height: 480px) {
                width: 100%;
            }

            .submenu-opened & {
                .transition(all);
                left: -370px;

                /*@media only screen and (max-width: 480px),(max-height: 480px) {
                    left: -100vw;
                }*/
            }

            .box-menu__parent {
                display: block;
                float: left;
                width: 370px;

                @media only screen and (max-width: 480px),(max-height: 480px) {
                    width: 100%;
                }
            }

            .box-menu__parent-item {
                height: auto;
                min-height: 120px;
                position: relative;
                
                @media only screen and (max-width: 480px),(max-height: 480px) {
                    height: 63px;
                    min-height: 63px;
                }
            }

            .box-menu__child {
                display: block;
                width: 370px;
                margin-top: 10px;
                //border-left: 2px solid @activityDotBackground;;
                margin-left: 45px;
                
                @media only screen and (max-width: 480px),(max-height: 480px) {
                    height: 48px;
                    line-height: 48px;                    
                }

                li {
                    a {
                        line-height: 36px;
                        height: 36px;
                        padding-left: 40px;
                        font-size: 15px;
                    }
                }

                @media only screen and (max-width: 480px),(max-height: 480px) {
                    width: 100vw;
                }

                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
            }
        }
    }
</style>
