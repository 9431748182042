<template>
    <div class="notification-menu__holder">
        <div class="notification-menu">
            <div class="notification-menu__header">
                <h3>{{ $t('notifications.title')}}</h3>
                <button class="btn-transparent collapse-btn" @click="collapse"><i class="icon-close-alt"></i></button>
            </div>

            <div class="notification-menu__wrap">
                <div class="notification-menu__inner-wrap">
                    <ul class="notification-menu__parent">
                        <li v-for="(item, index) in items"
                            :class="{'notification-menu__parent-item-toggled': toggledId === item.id}"
                            class="notification-menu__parent-item" :key="index">
                            <div class="notification-date">{{ item.date }}</div>
                            <span v-if="!item.read" class="notification-unread-badge label-badge label-badge-sm"></span>
                            <div class="notification-title">{{ item.title }}</div>
                            <div class="notification-message">{{ item.message }}</div>
                            <button class="btn-transparent notification-read-more"
                                    v-if="toggledId === item.id"
                                    @click="toggledId = null">
                                {{ $t('notifications.read-less')}}
                            </button>
                            <button v-else class="btn-transparent notification-read-more" @click="readMore(item)">
                                {{ $t('notifications.read-more')}}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "notifications",
        props: [
            'items',
        ],
        methods: {
            collapse() {
                this.expanded = false;
                this.$emit('notificationMenuCollapsed');
            },
            readMore(item) {
                this.toggledId = item.id;
            }
        },
        data() {
            return {
                expanded: false,
                toggledId: null,
            }
        }
    }
</script>

<style scoped lang="less">

    @import "../less/common.less";
    @import "../less/elements.less";

    .notification-menu__holder {
        position: absolute;
        top: 72px;
        right: -139px;
        z-index: 12;
        transition: height @transitionsLength;
    }

    .notification-menu {
        .box-default();
        width: 370px;
        max-height: 0;
        transition: all @transitionsLength;
        overflow: hidden;
    }

    .notification-menu__header {
        padding: 30px 21px;
        font-size: 24px;
        font-weight: 700;
        position: relative;
        transition: all @transitionsLength;

        .collapse-btn {
            position: absolute;
            right: 30px;
            top: 26px;
            color: @placeholderColor;
            i {
                font-size: 18px;
            }
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }

    .notification-menu__wrap {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 0;
        transition: all @transitionsLength;

        /*@media only screen and (max-height: 768px) {
            max-height: 53vh;
        }*/

        .notification-menu__inner-wrap {
            width: 720px;
            .transition(all);
            position: relative;
            left: 0;

            @media only screen and (max-width: 480px),(max-height: 480px) {
                width: 200vw;
            }

            .submenu-opened & {
                .transition(all);
                left: -370px;

                @media only screen and (max-width: 480px),(max-height: 480px) {
                    left: -100vw;
                }
            }

            .notification-menu__parent {
                display: block;
                float: left;
                width: 370px;

                @media only screen and (max-width: 480px),(max-height: 480px) {
                    width: 100vw;
                }
            }
            .notification-menu__parent-item {
                height: 102px;
                position: relative;
                padding: 10px 30px;
                border-bottom: 1px solid @borderBottomChat;
                margin-right: 14px;
            }

            .notification-date, .notification-message {
                font-size: 12px;
                line-height: 18px;
                color: @placeholderColor;
            }
            .notification-title {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: @inputFieldLabel;
            }
            .notification-message {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .notification-read-more {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: @primary;
                float: right;
                padding-top: 7px;
            }
            .notification-unread-badge {
                border: unset;
                background: @secondary;
                font-size: 10px;
                height: 10px;
                width: 10px;
                padding: 0 5px;
                position: absolute;
                right: 24px;
                top: 14px;
            }
            .notification-menu__parent-item-toggled {
                height: unset;
                padding-bottom: 32px;
                .notification-message {
                    overflow: visible;
                    white-space: unset;
                }
            }
        }
    }
</style>
