<template>
    <div id="progress_overlay" @click="toggleExpanded($event)" class="on-board__holder" :class="expanded ? 'is-expanded' : null">
        <div class="progress-title" :class="progressTitleClassName">
            <h3>{{$t('progress.title')}}</h3>
            <h3 class="completed">{{$t('progress.completed')}}</h3>
            <div class="progress-line">
                <span class="progress-fill" :class="progressPercent > 60 ? 'progress-fill-green': null" :style="progressFillStyle"></span>
            </div>
        </div>


        <div class="on-board__detail" v-if="expanded">
            <v-progress-circular
                :width="2"
                :size="100"
                :value="progressPercent"
                :color="progressColor"
                >
                <div class="circular-progress-inner">
                    <div class="circular-progress-done">{{ roundedProgressPercent }} %</div>
                    <div class="circular-progress-text">{{ $t('progress.done') }}</div>
                </div>
            </v-progress-circular>
            <div class="on-board__detail-text">
                <div class="on-board__detail-text-title">{{ $t('progress.did-you-known') }}</div>
                <div class="on-board__detail-text-message" v-html="getCurrentDidYouKnow()"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "onBoard",
        props: [
            'progressPercent'
        ],
        computed: {
            progressFillStyle: function () {
                let self = this;
                return {width: self.progressPercent + '%'};
            },
            progressTitleClassName: function () {
                let className = '';
                if (this.progressPercent >= 100) {
                    className += ' was-completed';
                }

                return className;
            },
            progressColor: function () {
                return this.progressPercent > 60 ? '#6DD230' : '#00A3E0';
            },
            roundedProgressPercent: function () {
                return parseInt(this.progressPercent);
            }            
        },
        methods: {
            toggleExpanded(ev) {
                if (ev) {
                    ev.preventDefault();
                }
                this.expanded = !this.expanded;
                this.$emit(this.expanded ? 'detailShowed' : 'detailHidden');
            },
            collapse() {
                this.expanded = false;
                this.$emit('detailHidden');
            },
            expand() {
                this.expanded = true;
                this.$emit('detailShowed');
            },
            getCurrentDidYouKnow: function () {
                return this.didYouKnowText;                
            },
            setCurrentDidYouKnow: function (text) {
                this.didYouKnowText = text;
            }
        },
        data() {
            return {
                expanded: true,
                didYouKnowText: ''
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/colors.less";
    #progress_overlay {
        top: 126px;
        height: 52px;
        line-height: 42px;
        overflow: hidden;
        cursor: pointer;
        
        @media only screen and (max-width: 480px),(max-height: 480px) {
            display: none;
        }

        .with-box-menu-expanded & {
            top: calc(100vh - 328px);
            
            @media only screen and (max-width: 480px),(max-height: 480px) {
                top: calc(100vh - 138px);
                display: block;
                
                &.is-expanded {
                    top: calc(100vh - 297px);
                }
            }
            
            @media only screen and (max-height: 690px) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }            
        }
        
        @media only screen and (max-width: 480px),(max-height: 480px) {
            width: calc(100vw - 40px);
        }

        .progress-title {
            position: relative;
            height: 52px;
            h3 {
                margin-top: 2px;
                font-weight: 400;
                width: 100%;
                height: 44px;
                color: @text;
                font-size: 12px;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(0, 0);
                transition: @transitionsLength all;

                &.completed {
                    transform: translate(0, -120%);
                    font-weight: 700;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }

            &.was-completed {
                h3 {
                    transform: translate(0, -120%);

                    &.completed {
                        transform: translate(0, 0);
                    }
                }
            }
        }
        &.is-expanded {
            height: 204px;
            .progress-line {
                border-radius: 0;
            }
        }

        .progress-line {
            width: 100%;
            height: 8px;
            position: absolute;
            top: 44px;
            left: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            overflow: hidden;

            .progress-fill {
                position: absolute;
                top: 0;
                left: 0;
                background: #00A3E0;
                display: block;
                height: 8px;
                &.progress-fill-green {
                    background: @onlineDot;
                }
            }
        }
        .on-board__detail {
            transition: all @transitionsLength;
            height: 152px;
            padding: 26px 16px;
            display: flex;
            .on-board__detail-text {
                max-width: 200px;
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                @media only screen and (max-width: 480px),(max-height: 480px) {
                    max-width: 150px;
                }
                
                .on-board__detail-text-title {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 6px;
                    color: @inputFieldLabel;
                }
                .on-board__detail-text-message {
                    font-size: 12px;
                    line-height: 18px;
                    color: @textLighter;
                }
            }
        }
        .circular-progress-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .circular-progress-done {
            font-weight: bold;
            font-size: 24px;
            line-height: 21px;
        }
        .circular-progress-text {
            font-size: 18px;
            line-height: 21px;
        }
    }
</style>