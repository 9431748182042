import CONFIG from './config.js'

export default {
    UI: {
        /**
         * Method for dynamic toggle of the elements, delayed class additions, etc.
         * 
         * @param {type} element
         * @param {type} $event
         * @param {type} forceHide
         * @returns {undefined}
         */
        dynamicToggle: function (element, $event, forceHide, debounced) {
            if ($event) {
                $event.preventDefault();
            }
            forceHide = forceHide || false;
            debounced = debounced || false;

            if (!element.isToggled && !forceHide) {
                element.isToggled = true;

                setTimeout(function () {
                    element.isVisible = true;
                }, CONFIG.DYNAMIC_TOGGLE_IN_DELAY);
            } else {
                if (!debounced) {
                    element.isVisible = false;

                    setTimeout(function () {
                        element.isToggled = false;
                    }, CONFIG.DYNAMIC_TOGGLE_TIMEOUT);
                } else {
                    element.debounceTimer = setTimeout(function () {
                        element.isVisible = false;

                        setTimeout(function () {
                            element.isToggled = false;
                        }, CONFIG.DYNAMIC_TOGGLE_TIMEOUT);
                    }, CONFIG.DEBOUNCE_TIME);
                }
            }
        },
        dynamicToggleCancelHide: function (elem) {
            if (elem.debounceTimer) {
                clearTimeout(elem.debounceTimer);
            }
        },
        cropText: function (text, maxLength) {
            if (text.length > maxLength) {
                let trimmedString = text.substr(0, maxLength);

                //re-trim if we are in the middle of a word and
                return trimmedString = trimmedString.substr(
                        0,
                        Math.min(+trimmedString.length, +trimmedString.lastIndexOf(' '))
                        ) + '...';
            } else {
                return text;
            }
        },
        handleInputBlur: function ($event) {
            var inputRow = $event.target.parentNode;
            if (inputRow.tagName !== 'DIV') {
                inputRow = inputRow.parentNode;
            }

            inputRow.className = inputRow.className.replace('focused', '').trim();
        },
        handleInputFocus: function ($event) {
            var inputRow = $event.target.parentNode;
            if (inputRow.tagName !== 'DIV') {
                inputRow = inputRow.parentNode;
            }

            inputRow.className = inputRow.className.replace('focused', '').trim();
            inputRow.className += ' focused';
        },
        formatDate: function (date) {
            return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes();
        }
    },
    isDefined: function (elem) {
        return typeof (elem) !== 'undefined';
    },
    hasDoneChecklistGroup: function (nr, checklist, progress) {
        let self = this;
        if(!nr) {
            return true;
        }
        
        let checklistValidate = checklist[nr];
        if (!checklistValidate) {
            return false;
        }

        let matchCount = 0;
        checklistValidate.map(function (item) {
            if (progress.scenes && self.isDefined(progress.scenes[item]) && progress.scenes[item]) {
                matchCount++;
            }

            if (progress.hotspots && self.isDefined(progress.hotspots[item]) && progress.hotspots[item]) {
                matchCount++;
            }
        });

        if (matchCount === checklistValidate.length) {
            return true;
        }

        return false;
    },
    clone: function (src) {
        let cloned = {};
        for (var srcKey in src) {
            cloned[srcKey] = src[srcKey];
        }
        return cloned;
    },
    setCookie: function (cname, cvalue, exhours) {
        let d = new Date();
        d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure";
    },
    getCookie: function (cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}
