export default Object.freeze({
    DEFAULT_LOCALE: 'cz',
    ROOT_PATH: process.env.NODE_ENV === 'production' ? '' : '',
    API_PATH: process.env.NODE_ENV === 'production' ? '/api/' : 'http://api.weboard.melocal/',
    NOTE_LENGTH: 200,
    DYNAMIC_TOGGLE_IN_DELAY: 50,
    DYNAMIC_TOGGLE_TIMEOUT: 500,
    DEBOUNCE_TIME: 300,
    MENU_TRANSLATION_PREFIX: 'menu.',
    MENU_USE_NUMBERS: true,
    DELAY_LANGUAGE_CHANGE: 100,
    MAIN_LOGO: './img/logo-weboard-deloitte.svg',
    MAIN_TITLE: 'Deloitte | Orientation day',
    LOGIN_BUTTON_CLASSNAME: 'btn-primary',
    LOGIN_SUBBUTTON_CLASSNAME: 'btn-gray text-primary',
    ABOUT_BUTTON_CLASSNAME: 'box-menu-other-info',
    ONFEED_EXPAND_CLASSNAME: 'text-gray',
    HIDE_SHOW_DELAY: 510,
    DISABLED_LOCALES: [],
    PLAY_AUDIO: false,
    SESSION_MAX_TIME: 0.1,
    REMEMBER_ME_TIME: 168,
    SESSION_CHECK_INTERVAL: 10000,
    ENABLE_STRING: 'd2JEbTIxKw==',
    VERSION: '1.0.0',
    CONTENT_MODAL_DEFAULTS: {
        type: 'default',
        videoId: null,
        title: '',
        content: '',
        hasHeader: true,
        hasFooter: false,
        addClass: '',
        image: '',
        form: null,
        isMustRead: false,
        wasRead: false,
        modalName: 'default',
        showText: true,
        buttonTitle: '',
        isSaved: false,
        sourceContent: null,
        hasCheckedSign: true,
        saveToNote: false,
        videoPoster: '',
        hideBottomButton: true,
    },
    MSALDefaultDomain: 'deloitte.weboard.me', 
    MSAL: {
        /*'deloitte.weboard.me': {
            auth: {
                clientId: 'd0110f2e-aabe-4bce-8791-b1d57b2ae5ef',
                authority: 'https://login.microsoftonline.com/fbd34963-3715-4d41-9f2a-e3034a3b7370',
                redirectUri: 'https://deloitte.weboard.me',
                postLogoutRedirectUri: 'https://deloitte.weboard.me',
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
            }
        },
        'localhost': {
            auth: {
                clientId: 'd0110f2e-aabe-4bce-8791-b1d57b2ae5ef',
                authority: 'https://login.microsoftonline.com/fbd34963-3715-4d41-9f2a-e3034a3b7370',
                redirectUri: 'http://localhost:8080',
                postLogoutRedirectUri: 'http://localhost:8080',
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
            }
        },*/
        'virtualtour-test.deloittece.com': {
            auth: {
                clientId: 'f7914ba8-b924-4413-a35f-9ddf6f9a7f6f',
                authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
                redirectUri: 'https://virtualtour-test.deloittece.com',
                postLogoutRedirectUri: 'https://virtualtour-test.deloittece.com',
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
            }
        }, //test
        'virtualtour.deloittece.com': {
            auth: {
                clientId: '693086e4-7b27-43f0-bd87-ac6ab4df7b6f',
                authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
                redirectUri: 'https://virtualtour.deloittece.com',
                postLogoutRedirectUri: 'https://virtualtour.deloittece.com',
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
            }
        } //prod
    }
});