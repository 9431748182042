<template>
    <div class="language-select">
        <div class="language-select__item" v-for="(lang, index) in getAvailableLanguages()" :class="{ 'selected': locale == lang }" v-bind:key="index" @click="setLang(lang)" :title="selectLabel(lang)">
            <img class="btn-lang" :src="require('@/assets/flag-' + lang + '.svg')" :alt="selectLabel(lang)" />
            <span class="language-select__item-text">{{ $t('select.langTitle', lang) }}</span>
            <span v-if="index !== getAvailableLanguages().length - 1" class="vertical-divider"></span>
        </div>
    </div>
</template>

<script>
    import CONFIG from '../config.js'
    import Helpers from '../helpers.js'

    export default {
        name: 'languageSelect',
        data() {
            return {
                locale: this.$root.$i18n.locale,
                languages: this.$i18n.availableLocales,
            }
        },
        methods: {
            toggleLangs: function ($event) {
                Helpers.UI.dynamicToggle(this, $event);
            },
            setLang: function (lang) {
                this.locale = lang;
                this.toggleLangs();
            },
            selectLabel: function (locale) {
                return this.$t('select.label', locale) + ': ' + this.$t('select.langTitle', locale);
            },
            getAvailableLanguages: function () {
                let languages = [];
                this.languages.map(function (lang) {
                    if (CONFIG.DISABLED_LOCALES.indexOf(lang) < 0) {
                        languages.push(lang);
                    }
                });

                return languages;
            }
        },
        watch: {
            locale(val) {
                this.$emit('beforeLanguageChange');

                var self = this;
                window.setTimeout(function () {
                    self.$root.$i18n.locale = val;
                    self.$emit('afterLanguageChange', val);
                }, CONFIG.DELAY_LANGUAGE_CHANGE);
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/common.less";
    @import "../less/elements.less";

    .btn-lang {
        .circle(24px);
        line-height: 24px;
        display: inline-block;
        top: 1px;

        img {
            .square(24px);
            display: block;
        }
    }

    .language-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: inherit;
        margin-left: 26px;
        height: 36px;
    }

    .language-select__item {
        display: flex;
        font-size: 12px;
        line-height: 14px;
        align-items: center;
        opacity: 0.5;
        cursor: pointer;
        img {
            margin-right: 16px;
        }
        &.selected {
            opacity: 1;
        }
    }

    .vertical-divider {
        border-right: 1px solid #252631;
        margin: 0 24px;
        height: 14px;
        opacity: 0.5;
    }
</style>

<i18n>
    {
    "en": {
    "select.label": "Select language",
    "select.langTitle": "English"
    },
    "cz": {
    "select.label": "Vybrat jazyk",
    "select.langTitle": "Čeština"
    }
    }
</i18n>
