<template>
    <div class="marzipano-view-wrapper">
        <div id="pano"></div>
        <div id="panoVRDisplay" ref="VRDisplayDOM"></div>
        <div id="panoCBDisplay" ref="CBDisplayDOM" :class="{ visible: CBDisplayToggled }" @click="leaveCBView"></div>

        <div class="dev-hidden">
            <a href="javascript:void(0)" id="viewUp" class="viewControlButton viewControlButton-1">
                <img class="icon" src="img/up.png">
            </a>
            <a href="javascript:void(0)" id="viewDown" class="viewControlButton viewControlButton-2">
                <img class="icon" src="img/down.png">
            </a>
            <a href="javascript:void(0)" id="viewLeft" class="viewControlButton viewControlButton-3">
                <img class="icon" src="img/left.png">
            </a>
            <a href="javascript:void(0)" id="viewRight" class="viewControlButton viewControlButton-4">
                <img class="icon" src="img/right.png">
            </a>
            <a href="javascript:void(0)" id="viewIn" class="viewControlButton viewControlButton-5">
                <img class="icon" src="img/plus.png">
            </a>
            <a href="javascript:void(0)" id="viewOut" class="viewControlButton viewControlButton-6">
                <img class="icon" src="img/minus.png">
            </a>
        </div>

        <button @click="debugView" v-if="debugViewEnabled" class="debug-view-button">D</button>
        <span v-if="debugViewEnabled" class="debug-view-pin-location"></span>
    </div>
</template>

<script>
    const Console = console;
    import CONFIG from '../../config.js'
            import appData from '../../AppData.json'

            export default {
                name: 'marzipanoView',
                methods: {
                    createLinkHotspotElement: function (hotspot) {
                        var self = this;

                        var wrapper = document.getElementById(hotspot.wrapperId);

                        self.stopTouchAndScrollEventPropagation(wrapper);

                        return wrapper;
                    },
                    createInfoHotspotElement: function (hotspot) {
                        var self = this;

                        var wrapper = document.getElementById(hotspot.wrapperId);

                        self.stopTouchAndScrollEventPropagation(wrapper);

                        return wrapper;
                    },
                    stopTouchAndScrollEventPropagation: function (element) {
                        if (!element) {
                            return;
                        }

                        var eventList = ['touchstart', 'touchmove', 'touchend', 'touchcancel',
                            'wheel', 'mousewheel'];
                        for (var i = 0; i < eventList.length; i++) {
                            element.addEventListener(eventList[i], function (event) {
                                event.stopPropagation();
                            });
                        }
                    },
                    findSceneById: function (id) {
                        for (var i = 0; i < this.scenes.length; i++) {
                            if (this.scenes[i].data.id === id) {
                                return this.scenes[i];
                            }
                        }
                        return null;
                    },
                    findSceneDataById: function (id) {
                        for (var i = 0; i < appData.scenes.length; i++) {
                            if (appData.scenes[i].id === id) {
                                return appData.scenes[i];
                            }
                        }
                        return null;
                    },
                    sanitize: function (s) {
                        return s.replace('&', '&amp;').replace('<', '&lt;').replace('>', '&gt;');
                    },
                    switchSceneByName: function (sceneName) {
                        console.log(this.$root.$i18n.locale);
                        var locale = this.$root.$i18n.locale;
                        var sceneKey = null;

                        this.scenes.map(function (scene) {
                            if (!scene) {
                                return;
                            }

                            if (!scene.data) {
                                return;
                            }

                            if (!scene.data.info) {
                                return;
                            }

                            var sceneInfo = scene.data.info;
                            if (!sceneInfo.link) {
                                return;
                            }

                            if (!sceneInfo.link[locale]) {
                                return;
                            }

                            var sceneLinkLocalized = sceneInfo.link[locale];
                            if (sceneLinkLocalized !== sceneName) {
                                return;
                            }

                            sceneKey = sceneInfo.sceneKey;
                        });

                        if (sceneKey && this.scenes[sceneKey]) {
                            this.switchScene(this.scenes[sceneKey]);
                        }
                    },
                    switchSceneByKey: function (sceneKey) {
                        this.switchScene(this.scenes[sceneKey]);
                    },
                    switchScene: function (scene) {
                        this.$emit('sceneChange', scene.data);
                        this.stopAutorotate();

                        var viewParameters = scene.data.initialViewParameters;
                        if (scene.data.initialViewParametersFromAnotherScene &&
                                scene.data.initialViewParametersFromAnotherScene[this.previousSceneId]) {
                            viewParameters = scene.data.initialViewParametersFromAnotherScene[this.previousSceneId];
                        }

                        scene.view.setParameters(viewParameters);
                        scene.scene.switchTo();

                        //Set current view for device orientation method
                        this.currentView = scene.view;
                        this.previousSceneId = scene.data.id;
                        this.currentScene = scene.scene;
                        this.progress.scenes[scene.data.id] = true;
                        if(scene.data.id === "5-sc03_id06_social-hub-7np" || scene.data.id === "4-sc03_id05_open-space") {
                          this.progress.scenes["6-sc03_id07_social-hub-7np"] = true;
                          this.progress.scenes["7-sc03_id08_social-hub-7np"] = true;
                          this.progress.hotspots["hs_1_2"] = true;
                        }
                        this.$emit('updateProgress', this.progress);
                    },
                    updateProgressForced: function(progress) {
                        this.progress = progress;
                        this.$emit('updateProgress', this.progress);
                    },
                    startAutorotate: function () {
                        this.viewer.startMovement(this.autorotate);
                        this.viewer.setIdleMovement(3000, this.autorotate);
                        this.autoRotateEnabled = true;
                    },
                    stopAutorotate: function () {
                        this.autoRotateEnabled = false;
                        this.viewer.stopMovement();
                        this.viewer.setIdleMovement(Infinity);
                    },
                    toggleGyroscope: function () {
                        if (!this.gyroscopeEnabled) {
                            var view = this.currentView;
                            this.deviceOrientationControlMethod.getPitch(function (err, pitch) {
                                if (!err) {
                                    view.setPitch(pitch);
                                }
                            });
                            this.viewer.controls().enableMethod('deviceOrientation');
                            this.gyroscopeEnabled = true;
                        } else {
                            this.viewer.controls().disableMethod('deviceOrientation');
                            this.gyroscopeEnabled = false;
                        }
                    },
                    toggleAutorotate: function () {
                        if (!this.autoRotateEnabled) {
                            this.startAutorotate();
                        } else {
                            this.stopAutorotate();
                        }
                    },
                    toggleFullScreen: function () {
                        var screenfull = window.screenfull;
                        try {
                            screenfull.toggle();
                        } catch (e) {
                            //I can't do fullscreen
                        }
                    },
                    leaveCBView: function() {
                        this.CBView = null;
                        this.CBDisplayToggled = false;
                        try {
                            window.screenfull.toggle();
                        } catch (e) {
                            //I can't do fullscreen
                        }
                        this.$emit('CBViewLeaved');
                    },
                    toggleCBDisplay: function () {
                        try {
                            window.screenfull.toggle();
                        } catch (e) {
                            //I can't do fullscreen
                        }
                        this.CBDisplayToggled = true;
                        var currentScene = this.currentScene;
                        
                        // Create viewer. WebGL support is required to set the projection center.
                        var viewerOpts = {stageType: 'webgl'};
                        
                        var self = this;
                        self.CBView = {};
                        self.CBView.viewer = new Marzipano.Viewer(document.getElementById('panoCBDisplay'), viewerOpts);
                        self.CBView.viewer.controls().registerMethod('deviceOrientation', this.deviceOrientationControlMethod);

                        // Create geometry.
                        //Need to reset to scene geometry of the selected scene
                        var geometry = currentScene._layers[0]._geometry;

                        // Create views.
                        var viewLimiter = Marzipano.RectilinearView.limit.traditional(3100, 100 * Math.PI / 180);
                        var viewLeft = new Marzipano.RectilinearView(null, viewLimiter);
                        var viewRight = new Marzipano.RectilinearView(null, viewLimiter);

                        // Get the stage.
                        var stage = self.CBView.viewer.stage();
                        stage.setSize({
                            width: document.body.clientWidth,
                            height: document.body.clientHeight
                        });

                        // Create layers.
                        var leftLayer = createLayer(stage, viewLeft, geometry, 'left',
                                {relativeWidth: 0.5, relativeX: 0});
                        var rightLayer = createLayer(stage, viewRight, geometry, 'right',
                                {relativeWidth: 0.5, relativeX: 0.5});

                        // Add layers to stage.
                        stage.addLayer(leftLayer);
                        stage.addLayer(rightLayer);

                        function createLayer(stage, view, geometry, eye, rect) {
                            var source = currentScene._layers[0]._source;

                            var textureStore = new Marzipano.TextureStore(source, stage);
                            var layer = new Marzipano.Layer(source, geometry, view, textureStore,
                                    {effects: {rect: rect}});

                            layer.pinFirstLevel();

                            return layer;
                        }
                        
                        this.deviceOrientationControlMethod.getPitch(function (err, pitch) {
                            if (!err) {
                                viewLeft.setPitch(pitch);
                                viewRight.setPitch(pitch);
                            }
                        });
                        self.CBView.viewer.controls().enableMethod('deviceOrientation');                        
                    },
                    toggleVRDisplay: function () {
                        this.VRDisplayToggled = true;
                        var currentScene = this.currentScene;
                        var self = this;
                        try {

                            // Create stage and register renderers.
                            self.VRDisplayStage = null;
                            self.VRDisplayStage = new Marzipano.WebGlStage();
                            Marzipano.registerDefaultRenderers(self.VRDisplayStage);

                            var viewerElement = self.$refs.VRDisplayDOM;

                            //Reset previous content
                            viewerElement.innerHTML = '';

                            // Insert stage into the DOM.
                            viewerElement.appendChild(self.VRDisplayStage.domElement());

                            // Update the stage size whenever the window is resized.
                            function updateSize() {
                                self.VRDisplayStage.setSize({
                                    width: viewerElement.clientWidth,
                                    height: viewerElement.clientHeight
                                });
                            }
                            updateSize();

                            window.addEventListener('resize', updateSize);

                            // Create geometry.
                            //Need to reset to scene geometry of the selected scene
                            var geometry = currentScene._layers[0]._geometry;

                            // Create view.
                            var viewLeft = new WebVrView();
                            var viewRight = new WebVrView();

                            // Create layers.
                            var layerLeft = createLayer(self.VRDisplayStage, viewLeft, geometry, 'left',
                                    {relativeWidth: 0.5, relativeX: 0});
                            var layerRight = createLayer(self.VRDisplayStage, viewRight, geometry, 'right',
                                    {relativeWidth: 0.5, relativeX: 0.5});

                            // Add layers into stage.
                            self.VRDisplayStage.addLayer(layerLeft);
                            self.VRDisplayStage.addLayer(layerRight);

                            setTimeout(function () {
                                self.VRDisplay.requestAnimationFrame(render);
                                self.VRDisplay.requestPresent([{source: self.VRDisplayStage.domElement()}]);
                            }, 500);

                            var proj = self.VRData.mat4.create();
                            var pose = self.VRData.mat4.create();

                            function render() {
                                var frameData = new VRFrameData;
                                self.VRDisplay.getFrameData(frameData);

                                // Update the view.
                                // The panorama demo at https://github.com/toji/webvr.info/tree/master/samples
                                // recommends computing the view matrix from `frameData.pose.orientation`
                                // instead of using `frameData.{left,right}ViewMatrix.
                                if (frameData.pose.orientation) {
                                    self.VRData.mat4.fromQuat(pose, frameData.pose.orientation);
                                    self.VRData.mat4.invert(pose, pose);

                                    self.VRData.mat4.copy(proj, frameData.leftProjectionMatrix);
                                    self.VRData.mat4.multiply(proj, proj, pose);
                                    viewLeft.setProjection(proj);

                                    self.VRData.mat4.copy(proj, frameData.rightProjectionMatrix);
                                    self.VRData.mat4.multiply(proj, proj, pose);
                                    viewRight.setProjection(proj);
                                }

                                // Render and submit to WebVR display.
                                self.VRDisplayStage.render();
                                self.VRDisplay.submitFrame();

                                // Render again on the next frame.
                                self.VRDisplay.requestAnimationFrame(render);
                            }

                            function createLayer(stage, view, geometry, eye, rect) {
                                var source = currentScene._layers[0]._source;

                                var textureStore = new Marzipano.TextureStore(source, stage);
                                var layer = new Marzipano.Layer(source, geometry, view, textureStore,
                                        {effects: {rect: rect}});

                                layer.pinFirstLevel();

                                return layer;
                            }

                            window.addEventListener('vrdisplaypresentchange', function (e) {
                                if (!self.VRDisplay.isPresenting) {
                                    self.$refs.VRDisplayDOM.innerHTML = '';
                                    self.VRDisplayStage.destroy();
                                }
                            });

                        } catch (e) {
                            //Nothing to do in case of error
                        }
                    },
                    resetSettings: function () {
                        var screenfull = window.screenfull;
                        if (screenfull.isFullscreen) {
                            screenfull.exit();
                        }

                        this.stopAutorotate();
                    },
                    debugView: function () {
                        Console.log('Debug view params:\n');
                        Console.log('{\n"yaw": ' + this.viewer._currentScene._view._yaw + ',\n"pitch": ' + this.viewer._currentScene._view._pitch + ',\n"fov": ' + this.viewer._currentScene._view._fov + '\n}\n');
                    }
                },
                mounted: function () {
                    /*
                     * TODO: Rewrite to VUE based
                     * 
                     **/
                    var Marzipano = window.Marzipano;
                    var screenfull = window.screenfull;
                    var self = this;

                    this.deviceOrientationControlMethod = new window.DeviceOrientationControlMethod();

                    try {
                        navigator.getVRDisplays().then(function (vrDisplays) {
                            if (vrDisplays.length > 0) {
                                self.hasVRDisplay = true;
                                self.VRDisplay = vrDisplays[0];

                                //Init objects and dependencies for the view
                                var VRData = {};
                                VRData.mat4 = Marzipano.dependencies.glMatrix.mat4;
                                VRData.quat = Marzipano.dependencies.glMatrix.quat;
                                VRData.degToRad = Marzipano.util.degToRad;
                                VRData.polyfill = new WebVRPolyfill();

                                self.VRData = VRData;

                                self.$emit('VRDisplayFound', true);
                            }
                        });
                    } catch (e) {
                        self.hasVRDisplay = false;
                        self.VRDisplay = null;
                    }


                    //Get elements
                    //TODO: Rewrite to better
                    var panoElement = document.querySelector('#pano');

                    // Detect desktop or mobile mode.
                    if (window.matchMedia) {
                        var setMode = function () {
                            if (mql.matches) {
                                document.body.classList.remove('desktop');
                                document.body.classList.add('mobile');
                            } else {
                                document.body.classList.remove('mobile');
                                document.body.classList.add('desktop');
                            }
                        };
                        var mql = matchMedia("(max-width: 500px), (max-height: 500px)");
                        setMode();
                        mql.addListener(setMode);
                    } else {
                        document.body.classList.add('desktop');
                    }

                    document.body.classList.add('no-touch');
                    window.addEventListener('touchstart', function () {
                        document.body.classList.remove('no-touch');
                        document.body.classList.add('touch');
                    });


                    // Viewer options.
                    var viewerOpts = {
                        controls: {
                            mouseViewMode: appData.settings.mouseViewMode
                        }
                    };

                    // Initialize viewer.
                    this.viewer = new Marzipano.Viewer(panoElement, viewerOpts);

                    // Create scenes.
                    this.scenes = appData.scenes.map(function (data) {
                        var urlPrefix = "tiles";
                        //TODO: Rewrite path to tiles
                        var source = Marzipano.ImageUrlSource.fromString(
                                urlPrefix + "/" + data.id + "/{z}/{f}/{y}/{x}.jpg",
                                {cubeMapPreviewUrl: urlPrefix + "/" + data.id + "/preview.jpg"});

                        var geometry = new Marzipano.CubeGeometry(data.levels);

                        var limiter = Marzipano.RectilinearView.limit.traditional(data.faceSize, 100 * Math.PI / 180, 120 * Math.PI / 180);
                        var view = new Marzipano.RectilinearView(data.initialViewParameters, limiter);

                        var scene = self.viewer.createScene({
                            source: source,
                            geometry: geometry,
                            view: view,
                            pinFirstLevel: true
                        });
                        
                        self.progress.scenes[data.id] = false;

                        
                        // Create link hotspots.
                        data.linkHotspots.forEach(function (hotspot) {
                            var element = self.createLinkHotspotElement(hotspot);
                            scene.hotspotContainer().createHotspot(element, {yaw: hotspot.yaw, pitch: hotspot.pitch});
                        });

                        // Create info hotspots.
                        data.infoHotspots.forEach(function (hotspot) {
                            var element = self.createInfoHotspotElement(hotspot);
                            self.progress.hotspots[hotspot.wrapperId] = false;
                            scene.hotspotContainer().createHotspot(element, {yaw: hotspot.yaw, pitch: hotspot.pitch});
                        });

                        return {
                            data: data,
                            scene: scene,
                            view: view
                        };
                    });

                    // Set up autorotate, if enabled.
                    this.autorotate = Marzipano.autorotate({
                        yawSpeed: 0.03,
                        targetPitch: 0,
                        targetFov: Math.PI / 2
                    });


                    /*if (appData.settings.autorotateEnabled) {
                     this.autorotateToggleElement.classList.add('enabled');
                     }*/

                    // Set handler for autorotate toggle.
                    //this.autorotateToggleElement.addEventListener('click', self.toggleAutorotate);

                    // Set up fullscreen mode, if supported.
                    if (screenfull.enabled && appData.settings.fullscreenButton) {
                        document.body.classList.add('fullscreen-enabled');
                        screenfull.on('change', function () {
                            self.fullscreenEnabled = screenfull.isFullscreen;
                        });
                    } else {
                        document.body.classList.add('fullscreen-disabled');
                    }

                    panoElement.querySelector('div').style.zIndex = 25;

                    // DOM elements for view controls.
                    var viewUpElement = document.querySelector('#viewUp');
                    var viewDownElement = document.querySelector('#viewDown');
                    var viewLeftElement = document.querySelector('#viewLeft');
                    var viewRightElement = document.querySelector('#viewRight');
                    var viewInElement = document.querySelector('#viewIn');
                    var viewOutElement = document.querySelector('#viewOut');

                    // Dynamic parameters for controls.
                    var velocity = 0.7;
                    var friction = 3;

                    // Associate view controls with elements.
                    var controls = this.viewer.controls();
                    controls.registerMethod('upElement', new Marzipano.ElementPressControlMethod(viewUpElement, 'y', -velocity, friction), true);
                    controls.registerMethod('downElement', new Marzipano.ElementPressControlMethod(viewDownElement, 'y', velocity, friction), true);
                    controls.registerMethod('leftElement', new Marzipano.ElementPressControlMethod(viewLeftElement, 'x', -velocity, friction), true);
                    controls.registerMethod('rightElement', new Marzipano.ElementPressControlMethod(viewRightElement, 'x', velocity, friction), true);
                    controls.registerMethod('inElement', new Marzipano.ElementPressControlMethod(viewInElement, 'zoom', -velocity, friction), true);
                    controls.registerMethod('outElement', new Marzipano.ElementPressControlMethod(viewOutElement, 'zoom', velocity, friction), true);
                    controls.registerMethod('deviceOrientation', this.deviceOrientationControlMethod);

                    var locale = this.$root.$i18n.locale;
                    var foundScene = false;

                    appData.scenes.map(function (scene) {
                        var sceneLink = CONFIG.ROOT_PATH + scene.info.link[locale];
                        var isSame = sceneLink === window.location.pathname;

                        if (isSame) {
                            foundScene = scene.info.sceneKey;
                        }
                    });

                    if (foundScene !== false) {
                        this.firstScene = foundScene;
                    }

                    this.switchScene(this.scenes[this.firstScene]);
                    /*this.$emit('openHalfBox'); */
                },
                data: function () {
                    return {
                        sensor: null,
                        firstScene: 0,
                        controls: null,
                        deviceOrientationControlMethod: null,
                        scenes: [],
                        sceneNameElement: null,
                        sceneListElement: null,
                        sceneElements: null,
                        sceneListToggleElement: null,
                        autorotateToggleElement: null,
                        fullscreenToggleElement: null,
                        autorotate: null,
                        viewer: null,
                        autoRotateEnabled: false,
                        fullscreenEnabled: false,
                        gyroscopeEnabled: false,
                        debugViewEnabled: false,
                        currentView: null,
                        previousSceneId: null,
                        currentScene: null,
                        isSteady: true,
                        changingTimeout: null,
                        VRDisplay: null,
                        hasVRDisplay: false,
                        VRDisplayToggled: false,
                        VRData: null,
                        VRDisplayStage: null,
                        CBDisplayToggled: false,
                        CBView: null,
                        progress: {
                            hotspots: {},
                            scenes: {}
                        }
                    };
                }
            }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .dev-hidden {
        display: none;
    }

    .debug-view-button {
        position: fixed;
        left: 600px;
        bottom: 0px;
        width: 600px;
        height: 20px;
        background: transparent;
        z-index: 20000;
        display: block;
        color: transparent;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        &:hover {
            background-color: rgba(255,255,255,.5);
        }
    }

    .debug-view-pin-location {
        position: fixed;
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        display: block;
        background: red;
        border-radius: 50%;
        z-index: 250;
        margin-top: -8px;
        margin-left: -8px;
    }

    #pano {
        &:before {
            content: "";
            display: block;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+90,0.73+100 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            width: 100vw;
            height: 20vh;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 10;
        }
    }
</style>
