<template>
    <div :class="fullCoverLoaderClass">
        <div class="loader-message">
            <h2>
                <i></i>
                <slot></slot>
            </h2>
        </div>
    </div>
</template>

<script>
            import Helpers from '@/helpers.js'

            export default {
                name: 'FullLoaderResult',
                props: {
                    addClass: {
                        'type': String,
                        'default': ''
                    }
                },
                computed: {
                    fullCoverLoaderClass: function () {
                        let fullCoverLoaderClass = 'full-cover';

                        if (this.isToggled) {
                            fullCoverLoaderClass += ' is-toggled';
                        }

                        if (this.isVisible) {
                            fullCoverLoaderClass += ' is-visible';
                        }

                        if (this.addClass) {
                            fullCoverLoaderClass += ' ' + this.addClass;
                        }

                        return fullCoverLoaderClass;
                    }
                },
                data: function () {
                    return {
                        isVisible: false,
                        isToggled: false
                    };
                },
                methods: {
                    toggle: function (disableDynamic) {
                        disableDynamic = disableDynamic || false;
                        if(!disableDynamic) {
                            Helpers.UI.dynamicToggle(this);
                        } else {
                            this.isVisible = !this.isVisible;
                            this.isToggled = !this.isToggled;
                        }
                    }
                }
            }
</script>

<style scoped lang="less">
    @import '../less/colors.less';
    
    .loader-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        h2 {
            font-size: 36px;
            color: @text;
            font-weight: 500;
            
            span {
                font-weight: 300;
                display: block;
                font-size: 24px;
                margin-top: 12px;
            }

            i {
                display: block;
                font-size: 24px;
                height: 90px;
                margin-bottom: 30px;
                color: @primary;
                position: relative;

                &:after {
                    position: absolute;
                    content: "";
                    display: block;
                    top: calc(50% - 45px);
                    left: calc(50% - 45px);
                    width: 90px;
                    height: 90px;

                    background: url(../assets/loading-spinner.svg) center center no-repeat;
                    background-size: cover;
                    -webkit-animation:spin 4s linear infinite;
                    -moz-animation:spin 4s linear infinite;
                    animation:spin 4s linear infinite;
                }
            }
        }
    }


</style>
