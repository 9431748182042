import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCarousel from 'vue-carousel';
import vSelect from 'vue-select';
import vueDebounce from 'vue-debounce';
import vuetify from './plugins/vuetify';
import Emitter from 'tiny-emitter';

// Set the components prop default to return our fresh components 
vSelect.props.components.default = () => ({
        OpenIndicator: {
            render: createElement => createElement('i', {
                    class: {
                        'icon-chevron-down': true
                    }
                }, '')
        },
        Deselect: {
            render: createElement => createElement('i', {
                    class: {
                        'icon-close-alt': true
                    }
                }, '')
        }
    })

// Register the component
Vue.component(vSelect)

Vue.use(vueDebounce, VueAxios, axios)

Vue.config.productionTip = false

const msal = {
    $msalInstance: {},
    $emitter: new Emitter()
};

new Vue({
    i18n,
    vuetify,
    msal,
    render: h => h(App)
}).$mount('#app')
