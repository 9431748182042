<template>
    <div class="marzipano-hotspot-holder" :id="id" :class="hotspotHolderClass" @mousemove="keepHotspotHover()" @mouseleave="hideHotspot($event)">
        <div class="marzipano-hotspot" :class="hotspotClass">
            <button class="marzipano-hotspot__btn" @click="triggerHotspotAction($event)" @mouseenter="toggleHotspot($event)" @mouseleave="hideHotspot($event)">
                <span class="marzipano-hotspot__btn-inner"><span class="marzipano-hotspot__btn-inner-icon" v-html="icon"></span></span>
                <span class="marzipano-hotspot__content" v-html="getText('hint')"></span>
            </button>
        </div>
        <div v-if="alreadyChecked" class="checked-icon-container">
            <i class="icon-check checked-icon"></i>
        </div>
    </div>
</template>

<script>
    import Helpers from '../../helpers.js'

    export default {
        name: 'marzipanoHotspot',
        props: {
            hotspot: Object,
            displayedScenes: Object
        },
        methods: {
            toggleHotspot: function ($event) {
                this.$emit('hotspotToggle', this.id);

                if (this.voiceOver) {
                    this.$emit('hotspotVoiceOver', this.voiceOver);
                }

                Helpers.UI.dynamicToggle(this, $event);
            },
            hideHotspot: function ($event) {
                Helpers.UI.dynamicToggle(this, $event, true, true);
            },
            toggleHotspotContent: function () {

                if (this.hasLocationLink) {
                    window.open(this.link);
                    return;
                }

                this.$emit('hotspotContentToggle', this);

                Helpers.UI.dynamicToggle(this.contentBox);
            },
            keepHotspotHover: function () {
                Helpers.UI.dynamicToggleCancelHide(this);
            },
            openLink: function () {
                this.$emit('openLink', this.targetSceneKey, this.needChecklistGroupPassed);
            },
            triggerHotspotAction: function () {
                if (this.isLinkHotspot) {
                    this.openLink();
                } else {
                    this.toggleHotspotContent();
                }
            },
            openMoreLink: function () {
                var moreLink = this.moreLink || false;
                var openForm = false;
                if (moreLink === 'form') {
                    moreLink = false;
                    openForm = true;
                }

                if (moreLink) {
                    var locale = this.$root.$i18n.locale;
                    var targetLink = moreLink;
                    if (typeof (targetLink) === 'object' && targetLink[locale]) {
                        targetLink = targetLink[locale];
                    }

                    window.open(targetLink);
                } else {
                    this.$emit('moreLinkClicked', openForm);
                }
            }
        },
        computed: {
            hotspotClass() {
                var className = [];
                if (this.isToggled) {
                    className.push('toggled');
                }

                if (this.isVisible) {
                    className.push('visible');
                }

                if (this.position !== 'left') {
                    className.push('right');
                }

                var locale = this.$root.$i18n.locale;
                className.push('hotspot-' + locale);

                if (this.addClass) {
                    className.push(this.addClass);
                }

                if (this.noHint) {
                    className.push('no-hint');
                }

                return className.join(' ');
            },
            iconArrowClass() {
                return 'arrow icon-plus';
            },
            hotspotHolderClass() {
                var hotspotHolderClass = [];

                if (this.hotspotImageBlock) {
                    hotspotHolderClass.push('has-image-block');
                }

                if (this.addClass) {
                    hotspotHolderClass.push(this.addClass);
                }

                if (this.contentBox && this.contentBox.isVisible) {
                    hotspotHolderClass.push('toggled-extra-text');
                }

                if (this.isToggled) {
                    hotspotHolderClass.push('active-holder');
                }

                if (this.noHint) {
                    hotspotHolderClass.push('no-hint');
                }

                if (this.isLinkHotspot) {
                    hotspotHolderClass.push('is-link-hotspot');
                    
                    var isFirst = false;
                    if(this.target.indexOf('dcaf') > 0) {
                        isFirst = true;
                    }
                    
                    if (isFirst || (this.targetSceneKey && this.displayedScenes[this.targetSceneKey])) {
                        hotspotHolderClass.push('was-passed');
                    } else {
                        hotspotHolderClass.push('unvisited');
                    }                    
                }

                if (this.isLinkHotspot && !this.canGoNext) {
                    hotspotHolderClass.push('disabled');
                }

                if (this.invisibleHotspot) {
                    hotspotHolderClass.push('invisible');
                }

                return hotspotHolderClass.join(' ');
            }
        },
        data() {
            var hotspot = this.$props.hotspot || {};

            var o = {};
            o.alreadyChecked = false;
            o.id = hotspot.wrapperId || null;
            o.debounceTimer = null;
            o.isLinkHotspot = hotspot.isLinkHotspot || false;
            o.isVisible = false;
            o.isToggled = false;
            o.position = hotspot.position || 'left';
            o.getText = function (name) {
                name = name || 'content';

                if (typeof (this[name]) === 'string') {
                    return this[name];
                }

                var locale = this.$root.$i18n.locale;

                if (this[name][locale]) {
                    return this[name][locale];
                }

                return '';
            };

            if (hotspot.isLinkHotspot) {
                o.content = false;
                o.noHint = hotspot.noHint || false;
                o.target = hotspot.target || false;
                o.targetSceneKey = hotspot.targetSceneKey || false;
                o.needChecklistGroupPassed = hotspot.checklistGroupPass || false;
                o.canGoNext = true;
                if (o.needChecklistGroupPassed) {
                    o.canGoNext = false;
                }
                o.addClass = hotspot.addClass || false;
                o.location = hotspot.location || {};
                o.icon = '<i class="icon-arrow-circle"></i>';
                if (hotspot.locationReplace) {
                    o.location = hotspot.locationReplace;
                }

                o.hint = o.location;

                return o;
            }

            o.title = hotspot.details.title || null;
            o.content = hotspot.details.content || {};
            o.hint = hotspot.details.hint || '';
            o.hotspotIframeUrl = hotspot.details.content.iframeUrl || false;
            o.getHotspotIframeUrl = function () {
                var locale = this.$root.$i18n.locale;
                var mapIframeUrl = this.hotspotIframeUrl || '';
                return mapIframeUrl.replace(/!1sde!2sde/igm, '!1s' + locale + '!2s' + locale);
            };
            o.hotspotImageUrl = hotspot.details.content.imageUrl || false;
            o.hotspotImageBlock = o.hotspotIframeUrl || o.hotspotImageUrl;
            o.addClass = hotspot.details.addClass || false;
            o.icon = hotspot.details.icon || null;
            o.voiceOver = hotspot.details.voiceOver || null;
            o.invisibleHotspot = hotspot.details.invisibleHotspot || false;
            o.onloadTrigger = hotspot.details.onloadTrigger || false;

            o.contentBox = {
                isVisible: false,
                isToggled: false
            };

            if (hotspot.details.link) {
                o.moreLink = hotspot.details.link;
                o.hasLink = true;
            } else {
                o.moreLink = false;
                o.hasLink = false;
            }

            if (hotspot.details.content && hotspot.details.content.type && hotspot.details.content.type === 'location') {
                o.locationPin = true;
            } else {
                o.locationPin = false;
            }

            return o;
        }
    }
</script>

<style lang="less">
    @import "../../less/common.less";

    @keyframes pulse {
        0% {
            transform: scale(.9);
            -webkit-transform: scale(.9);
        }
        70% {
            transform: scale(1);
            -webkit-transform: scale(1);
            box-shadow: 0 0 0 @hotspotBtnSize*0.5 rgba(@secondary, 0);
        }
        100% {
            transform: scale(.9);
            -webkit-transform: scale(.9);
            box-shadow: 0 0 0 0 rgba(@secondary, 0);
        }
    }
    
    @keyframes pulsesm {
        0% {
            
        }
        70% {
            box-shadow: 0 0 0 @hotspotBtnSize*0.1666 rgba(@secondary, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(@secondary, 0);
        }
    }
    
    @keyframes pulsesize {
        0% {
            transform: scale(1);
            -webkit-transform: scale(1);
        }
        70% {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
        }
        100% {
            transform: scale(1);
            -webkit-transform: scale(1);
        }
    }
    
    .marzipano-hotspot-holder {
        position: absolute;
        z-index: 12;
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;

        .checked-icon-container {
            position: absolute;
            width: 24px;
            height: 24px;
            top: 0;
            margin-left: 15px;
            margin-top: -20px;
            overflow: hidden;
            border-radius: 50%;
            background: @checkedHotspotColor;
            text-align: center;
            padding-top: 2px;

            .checked-icon {
                border-radius: 50%;
                font-size: 20px;
                color: @btn-default-bg;
                transition: all @transitionsLength;
            }
        }

        &.disabled {
            opacity: .33;
        }

        &.invisible {
            opacity: 0;
            z-index: -1;
        }
        
        &.unvisited {
            &:after {
                display: block;
                content: "";
                position: absolute;
                top: -@hotspotBtnSize;
                left: -@hotspotBtnSize * 0.5;
                z-index: -5;
                .roundedRect(@hotspotBtnSize, @hotspotBtnSize, @hotspotBtnSize * 0.5);
                box-shadow: 0 0 0 0 rgba(@secondary, .5);
                -webkit-animation: pulse 1.5s infinite;                
            }
            
            &:before {
                display: block;
                content: "";
                position: absolute;
                top: -@hotspotBtnSize;
                left: -@hotspotBtnSize * 0.5;
                z-index: -5;
                .roundedRect(@hotspotBtnSize, @hotspotBtnSize, @hotspotBtnSize * 0.5);
                box-shadow: 0 0 0 0 @secondary;
                -webkit-animation: pulsesm 1.5s infinite;                
            }
            
            &.disabled {
                &:after {
                    display: none;
                }
                
                &:before {
                    display: none;
                }
            }
        }
    }

    .marzipano-hotspot {
        background: rgba(255, 255, 255, 0);
        .roundedRect(@hotspotBtnSize, @hotspotBtnSize, @hotspotBtnSize * 0.5);
        .shadow();
        text-align: left;
        transition: @transitionsLength all;
        overflow: hidden;
        top: -@hotspotBtnSize;
        left: -@hotspotBtnSize * 0.5;
        position: relative;
        font-size: @hotspotWrapContentFontSize;

        &.visible {
            .roundedRect(@hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding, @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding, @hotspotBtnSize * 0.5 + @hotspotBtnBorderSize + @hotspotWrapContentPadding);
            top: -@hotspotBtnSize - @hotspotBtnBorderSize - @hotspotWrapContentPadding;
            left: -@hotspotBtnSize * 0.5 - @hotspotBtnBorderSize - @hotspotWrapContentPadding;
            background: rgba(255, 255, 255, 1);
            width: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding + 300px;

            &.short-150 {
                width: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding + 150px;
            }

            &.short-200 {
                width: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding + 250px;
            }

            &.short-250 {
                width: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding + 250px;
            }
            .marzipano-hotspot__content {
                opacity: 1;
                transition: @fastTransitionsLength all;
                transition-delay: @transitionsLength;
            }
        }
    }

    .marzipano-hotspot__content {
        opacity: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding;
        height: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding;
        line-height: @hotspotBtnSize + 2 * @hotspotBtnBorderSize + 2 * @hotspotWrapContentPadding;
        transition: @transitionsLength all;
        color: @inputFieldLabel;
        font-size: @hotspotWrapContentFontSize;
        text-align: left;
        font-weight: 700;
    }

    .marzipano-hotspot__btn {
        display: block;
        background: fade(@primary, 0%);
        .circle(@hotspotBtnSize);
        margin: 0;
        transition: @transitionsLength all;
        cursor: pointer;
        color: #fff;
        
        .marzipano-hotspot__btn-inner {
            background: @primary;
            display: block;
            .circle(@hotspotBtnSize);
            margin: 0;
            transition: @transitionsLength all;
            line-height: @hotspotBtnSize;
            text-align: center;

            .is-link-hotspot & {
                background: @secondary;
            }

            .was-passed & {
                background: @wasPassedLink;
            }
        }

        .is-link-hotspot & {
            background: fade(@secondary, 20%);
        }

        .was-passed & {
            background: fade(@wasPassedLink, 20%);
        }

        i {
            color: #fff;
            font-size: 140%;
            vertical-align: middle;
            position: relative;
            top: -1px;
            &.smaller {
                font-size: 100%;
                top: -3px;
            }
        }

        .visible & {
            background: fade(@primary, 50%);
            .circle(@hotspotBtnSize + 2 * @hotspotBtnBorderSize);
            margin: @hotspotWrapContentPadding;

            .is-link-hotspot & {
                background: fade(@secondary, 50%);
            }

            .was-passed & {
                background: fade(@wasPassedLink, 20%);
            }

            .marzipano-hotspot__btn-inner {
                margin: @hotspotBtnBorderSize;
            }
        }
    }

</style>
