<template>
    <div class="marzipano-wrapper">
        <marzipano-view @updateProgress="updateProgress" @sceneChange="sceneChange" ref="marzipanoView" @openHalfBox="openHalfBox" @VRDisplayFound="VRDisplayFound" @CBViewLeaved="CBViewLeaved" />

        <marzipano-hotspot v-for="hotspot in hotspots" :hotspot="hotspot" :key="hotspot.id" @hotspotToggle="hideHotspots($event)" @hotspotContentToggle="hotspotContentToggle" ref="hotspotsGroup" @moreLinkClicked="moreLinkClicked" @hotspotVoiceOver="hotspotVoiceOver" />

        <marzipano-hotspot v-for="linkHotspot in linkHotspots" :hotspot="linkHotspot" :key="linkHotspot.id" :displayedScenes="displayedScenes" @hotspotToggle="hideLinkHotspots($event)" @openLink="openLinkValidate" ref="linkHotspotsGroup" />

        <div class="marzipano-hotspot__extra-text marzipano-hotspot__mobile-overlay" v-if="hotspotMobileContent.content" :class="hotspotMobileClass">
            <div class="row-full marzipano-hotspot__extra-text-wrap">
                <div class="col-full marzipano-hotspot__extra-text-icon"><span v-html="hotspotMobileContent.icon"></span></div>
                <div class="col-full marzipano-hotspot__extra-text-content">
                    <h3><span v-html="hotspotMobileContent.getText('title')"></span></h3>
                    <div class="inner-text" v-html="hotspotMobileContent.getText('content')"></div>
                    <div class="more-button-wrap" v-if="!hotspotMobileContent.locationPin && hotspotMobileContent.hasLink">
                        <button class="btn-bordered" @click="hotspotMobileContent.openMoreLink()">{{$t('more-info')}}</button>
                    </div>
                    <div class="more-button-wrap" v-if="hotspotMobileContent.locationPin">
                        <div class="more-button-pin-inner">
                            <span class="distance" v-if="hotspotMobileContent.content.distance">{{hotspotMobileContent.content.distance}}</span>
                            <button class="btn-bordered" @click="hotspotMobileContent.openMoreLink()">{{$t('lets-go')}}</button>
                        </div>
                    </div>
                </div>
                <div class="col-full marzipano-hotspot__extra-text-close">
                    <button class="marzipano-hotspot__close" @click="hideHotspotMobileContent()"><i class="icon-close"></i></button>
                </div>
                <div class="col-full marzipano-hotspot__extra-text-image-block" v-if="hotspotMobileContent.hotspotImageBlock">
                    <img :src="require('@/assets/hotspots/' + hotspotMobileContent.hotspotImageUrl)" :alt="hotspotMobileContent.getText('title')" v-if="hotspotMobileContent.hotspotImageUrl" />
                    <iframe :src="hotspotMobileContent.getHotspotIframeUrl()" v-if="hotspotMobileContent.hotspotIframeUrl"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import marzipanoView from './marzipanoView/marzipanoView.vue'
    import marzipanoHotspot from './marzipanoHotspot/marzipanoHotspot.vue'
    import Helpers from '../helpers.js'

    export default {
        name: 'marzipano',
        components: {
            marzipanoView,
            marzipanoHotspot
        },
        props: {
            hotspots: Array,
            linkHotspots: Array,
            checklist: Object,
            displayedScenes: Object
        },
        data() {
            return {
                sceneId: null,
                contentToggled: null,
                hotspotMobileContentBox: {
                    isVisible: false,
                    isToggled: false
                },
                hotspotMobileContent: {
                    content: null
                },
                alreadyCheckedHotspots: {},
            };
        },
        computed: {
            hotspotMobileClass: function () {
                var hotspotMobileClass = [];

                if (this.hotspotMobileContentBox.isVisible) {
                    hotspotMobileClass.push('visible');
                }

                if (this.hotspotMobileContentBox.isToggled) {
                    hotspotMobileClass.push('toggled');
                }

                if (this.hotspotMobileContent.addClass) {
                    hotspotMobileClass.push(this.hotspotMobileContent.addClass);
                }

                return hotspotMobileClass.join(' ');
            },
            hotspotChecked(hotspot) {
                return this.alreadyCheckedHotspots[hotspot.id];
            }
        },
        methods: {
            updateProgressFromStored: function(progress) {
                this.$refs.marzipanoView.updateProgressForced(progress);
                
                this.$refs.hotspotsGroup.map(function (hotspot) {
                    hotspot.alreadyChecked = progress.hotspots[hotspot.id] || false;
                });
            },
            updateProgress: function (progress) {
                this.alreadyCheckedHotspots = progress.hotspots;

                let checklist = this.checklist;
                this.$refs.linkHotspotsGroup.map(function (linkHotspot) {

                    if (Helpers.hasDoneChecklistGroup(linkHotspot.needChecklistGroupPassed, checklist, progress)) {
                        linkHotspot.canGoNext = true;
                        linkHotspot.$forceUpdate();
                    }
                });

                this.$emit('updateProgress', progress);
            },
            VRDisplayFound: function () {
                this.$emit('VRDisplayFound');
            },
            CBViewLeaved: function () {
                this.$emit('CBViewLeaved');
            },
            toggleVRDisplay: function () {
                this.$refs.marzipanoView.toggleVRDisplay();
            },
            toggleCBDisplay: function () {
                this.$refs.marzipanoView.toggleCBDisplay();
            },
            sceneChange: function (scene) {
                this.sceneId = scene.sceneId;

                this.$emit('sceneChange', scene);
            },
            hotspotVoiceOver: function (voiceOver) {
                this.$emit('hotspotVoiceOver', voiceOver);
            },
            switchSceneByName: function (sceneName) {
                this.hideHotspots();
                this.hideLinkHotspots();
                this.hideToggledContent();

                this.$refs.marzipanoView.switchSceneByName(sceneName);
            },
            switchSceneByKey: function (sceneKey) {
                this.hideHotspots();
                this.hideLinkHotspots();
                this.hideToggledContent();

                this.$refs.marzipanoView.switchSceneByKey(sceneKey);
            },
            openLinkValidate: function (sceneKey, passedChecklistGroup) {
                this.$emit('openLinkValidate', sceneKey, passedChecklistGroup);
            },
            openLink: function (sceneKey) {
                this.hideHotspots();
                this.hideLinkHotspots();
                this.hideToggledContent();

                this.$emit('hideOpenedHotspot');
                this.$refs.marzipanoView.switchSceneByKey(sceneKey);
            },
            toggleGyroscope: function () {
                this.$refs.marzipanoView.toggleGyroscope();
            },
            toggleAutorotate: function () {
                this.$refs.marzipanoView.toggleAutorotate();
            },
            toggleFullScreen: function () {
                this.$refs.marzipanoView.toggleFullScreen();
            },
            resetSettings: function () {
                this.$refs.marzipanoView.resetSettings();
            },
            hideToggledContent: function (toToggleID) {
                toToggleID = toToggleID || false;

                if (!this.$refs.hotspotsGroup) {
                    return;
                }

                this.$refs.hotspotsGroup.map(function (hotspot) {
                    if (hotspot.id !== toToggleID) {
                        Helpers.UI.dynamicToggle(hotspot.contentBox, null, true);
                    }
                });
            },
            hideHotspotMobileContent: function () {
                Helpers.UI.dynamicToggle(this.hotspotMobileContentBox, null, true);
                this.$emit('hotspotContentToggled', false);

                var self = this;
                window.setTimeout(function () {
                    self.hotspotMobileContent.contentBox.isVisible = false;
                    self.hotspotMobileContent.contentBox.isToggled = false;
                    self.hotspotMobileContent.isVisible = false;
                    self.hotspotMobileContent.isToggled = false;
                }, 600);
            },
            toggleContentFromParent: function(wrapperId) {
                var self = this;
                self.$refs.marzipanoView.progress.hotspots[wrapperId] = true;
                self.updateProgress(self.$refs.marzipanoView.progress);
            },
            hotspotContentToggle: function (hotspot) {
                this.$emit('hideOpenedHotspot');

                var toToggleID = hotspot.id;
                this.hideToggledContent(toToggleID);

                if (!hotspot.contentBox.isVisible) {
                    this.hotspotMobileContent = hotspot;
                    this.$emit('hotspotContentToggled', true);

                    Helpers.UI.dynamicToggle(this.hotspotMobileContentBox);
                }

                var params = {};
                params.yaw = hotspot.hotspot.yaw;
                params.pitch = hotspot.hotspot.pitch;
                params.fov = this.$refs.marzipanoView.currentView.fov();
                
                var self = this;
                if (self.$refs.marzipanoView.currentScene && self.$refs.marzipanoView.currentScene.lookTo) {
                    self.$refs.marzipanoView.progress.hotspots[hotspot.hotspot.wrapperId] = true;
                    self.updateProgress(self.$refs.marzipanoView.progress);
                    if(!hotspot.invisibleHotspot) {
                        self.$refs.marzipanoView.currentScene.lookTo(params, null);
                    }
                }

                const alreadyCheckedHotspots = this.alreadyCheckedHotspots;
                this.$refs.hotspotsGroup.map(function (hotspot) {
                    hotspot.alreadyChecked = alreadyCheckedHotspots[hotspot.id];
                });

                setTimeout(function () {
                    self.$emit('toggleHotspotContent', hotspot.hotspot);
                }, 500);
            },
            hideHotspots: function (toToggleID) {
                if (!this.$refs.hotspotsGroup) {
                    return;
                }

                this.$refs.hotspotsGroup.map(function (hotspot) {
                    if (hotspot.id !== toToggleID) {
                        hotspot.hideHotspot();
                    }
                });
            },
            hideLinkHotspots: function (toToggleID) {
                if (!Helpers.isDefined(this.$refs.linkHotspotsGroup)) {
                    return;
                }

                this.$refs.linkHotspotsGroup.map(function (hotspot) {
                    if (hotspot.id !== toToggleID) {
                        hotspot.hideHotspot();
                    }
                });
            },
            openHalfBox: function () {
                this.$emit('openHalfBox');
            },
            moreLinkClicked: function (openForm) {
                this.$emit('moreLinkClicked', openForm);
            }
        }

    }
</script>
